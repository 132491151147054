import {
  type BaseObjectRevision,
  RevisionAction,
} from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { CreatedFeed } from '@smack/core/views/Feeds/RevisionsFeed/BaseObjectRevisionFeed/CreatedFeed';
import { UpdatedFeed } from '@smack/core/views/Feeds/RevisionsFeed/BaseObjectRevisionFeed/UpdatedFeed';
import { RevisionFeed } from '@smack/core/views/Feeds/RevisionsFeed/RevisionFeed';
import type React from 'react';

interface IProps {
  revision: BaseObjectRevision;
  first: boolean;
  last: boolean;
  baseObject: BaseObject;
}

export const BaseObjectRevisionFeed: React.FC<IProps> = ({
  revision,
  first,
  last,
  baseObject,
}) => {
  const revisionProps = {
    revision: revision,
    first,
    last,
    baseObject,
  };

  switch (revision.revisionType) {
    case RevisionAction.CREATED:
      return <CreatedFeed {...revisionProps} />;
    case RevisionAction.UPDATED:
      return <UpdatedFeed {...revisionProps} />;
    default:
      return <RevisionFeed {...revisionProps} />;
  }
};
