import { captureException } from '@sentry/react';
import { User } from '@smack/core/api/models/users/User';
import type { AvailableView } from '@smack/core/components/DataDisplay/CalendarTimeLine/enums';
import type { ViewName } from '@smack/core/hooks/views/types';
import type { ITheme } from '@smack/core/store/app/types';
import type { IFilters } from '@smack/core/utils/Filters';
import type { ColumnPinning } from '@smack/core/views/TableView/utils';
import { uuid } from '@tinymce/tinymce-react/lib/es2015/main/ts/Utils';
import {
  type Path,
  type PathValue,
  getByPath,
  setByPath,
} from 'dot-path-value';
import { UserPreferencesSerializerSchema } from './UserPreferencesSerializerSchema';

export interface CustomersPreferences {
  menuCollapsed?: boolean;
  theme?: ITheme;
  homeListCollapsed?: Record<string, boolean>;
  leftCategoriesListCollapsed?: Record<string, boolean>;
  objectLinkedCalendar?: CalendarPreferences;
}

export interface CalendarPreferences {
  view?: AvailableView;
  viewType?: { [view: string]: string };
  zoom?: { [viewType: string]: number };
}

export interface MapsPreferences {
  zoom?: number;
  center?: {
    lat: number;
    lng: number;
  };
  basemap?: number;
}

export interface ViewPreference {
  default?: boolean;
  filters?: IFilters;
  columnWidth?: Record<string, number>;
  tableInitialOffset?: number;
  hiddenColumns?: string[];
  columnOrder?: string[];
  columnPinning?: ColumnPinning;
  calendarView?: string;
  leftPanel?: string;
  tableMenuCollapse?: boolean;
  tableShowSectionHeaders?: boolean;
  statsOpen?: boolean;
}

export type PreferencesByView = {
  [preferencesId: string]: ViewPreference;
};

export interface CategoryPreferences {
  currentView?: ViewName;
  views?: {
    [key: string]: PreferencesByView;
  };
}

interface CategoriesPreferences {
  [categoryId: string]: CategoryPreferences | undefined;
}

export interface IPreferences {
  maps?: { map?: MapsPreferences };
  categories?: { category?: CategoriesPreferences };
  customers?: { customer?: CustomersPreferences };
}

export class UserPreferences {
  preferences: IPreferences = {};

  userId: number;

  private timeout: NodeJS.Timeout | null = null;

  constructor(preferences: IPreferences, userId: number) {
    this.userId = userId;
    try {
      this.preferences = UserPreferencesSerializerSchema.parse(
        preferences,
      ) as IPreferences;
    } catch (error) {
      this.preferences = {};
    }
  }

  getIdPrefix(categoryId: number, view: ViewName): string {
    return `preference-${categoryId}-${view.toLowerCase()}`;
  }

  generateUniqueId(categoryId: number, view: ViewName): string {
    return uuid(this.getIdPrefix(categoryId, view));
  }

  getCategoryPreferenceByView(
    categoryId: number,
    view: ViewName,
    preferencesId?: string,
  ): { preferences?: ViewPreference; id?: string } | undefined {
    const preferencesByView = this.getCategoryPreferencesByView(
      categoryId,
      view,
    );
    let id = preferencesId;
    let preferences = preferencesId
      ? preferencesByView?.[preferencesId]
      : undefined;
    if (!preferences && preferencesByView) {
      const entry = Object.entries(preferencesByView).find(
        ([, v]) => v.default,
      );
      if (entry) {
        const [key, val] = entry;
        id = key;
        preferences = val;
      }
    }
    return { preferences, id };
  }

  getCategoryPreferences(id: number): CategoryPreferences | undefined {
    return this.getPreferences(
      `categories.category.${id}` as Path<IPreferences>,
    ) as CategoryPreferences;
  }

  setCategoryPreferenceByView(
    value: ViewPreference,
    categoryId: number,
    view: ViewName,
    preferencesId?: string,
  ): void {
    const prefByViewIsDefault = this.getCategoryPreferenceByView(
      categoryId,
      view,
      preferencesId,
    )?.preferences?.default;
    const prefByViewhasDefault = this.getCategoryPreferenceByView(
      categoryId,
      view,
    );
    if (prefByViewIsDefault || !prefByViewhasDefault?.preferences?.default) {
      value.default = true;
    }
    const prefId =
      preferencesId ??
      prefByViewhasDefault?.id ??
      this.generateUniqueId(categoryId, view);
    this.setPreferences(
      `categories.category.${categoryId}.views.${view.toLowerCase()}.${prefId}` as Path<IPreferences>,
      value as PathValue<
        IPreferences,
        'categories.category.${string}.views.${string}'
      >,
    );
  }

  setCategoryPreference(value: CategoryPreferences, categoryId: number) {
    return this.setPreferences(
      `categories.category.${categoryId}` as Path<IPreferences>,
      value as PathValue<IPreferences, 'categories.category.${string}'>,
    );
  }

  getMapsPreferences(): MapsPreferences | undefined {
    return this.getPreferences('maps.map');
  }

  setMapsPreferences(value: MapsPreferences) {
    return this.setPreferences('maps.map', value);
  }

  getCustomersPreferences(): CustomersPreferences | undefined {
    return this.getPreferences('customers.customer');
  }

  setCustomersPreferences(value: CustomersPreferences) {
    return this.setPreferences('customers.customer', value);
  }

  private getPreferences<T extends Path<IPreferences>>(keys: T) {
    // will be replaced by an api call when the back will be ready
    return getByPath(this.preferences, keys);
  }

  setPreferences<T extends Path<IPreferences>>(
    keys: Path<IPreferences>,
    value: PathValue<IPreferences, T>,
  ): void {
    try {
      this.preferences = {
        ...(UserPreferencesSerializerSchema.parse(
          setByPath(this.preferences, keys, value),
        ) as IPreferences),
      };

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        User.patchUser({ preferences: this.preferences });
      }, 500);
    } catch (err) {
      captureException(err);
    }
  }

  private getCategoryPreferencesByView(
    id: number,
    view: ViewName,
  ): PreferencesByView | undefined {
    const categoryPreferences = this.getCategoryPreferences(id);
    return categoryPreferences?.views?.[view.toLowerCase()];
  }
}
