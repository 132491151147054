import type { ChecklistItemLog } from '@smack/core/api/models/tasks/ChecklistItemLog';
import { ChecklistItemLogCheckedItemFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistItemLogFeed/ChecklistItemLogCheckedFeed';
import { ChecklistItemLogModificationFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistItemLogFeed/ChecklistItemLogModificationFeed';
import { ChecklistItemLogRenamedFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistItemLogFeed/ChecklistItemLogRenamedFeed';
import { ChecklistItemLogUncheckedItemFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistItemLogFeed/ChecklistItemLogUncheckedFeed';
import type React from 'react';

interface IProps {
  log: ChecklistItemLog;
  first: boolean;
  last: boolean;
}

export const ChecklistItemLogFeed: React.FC<IProps> = ({
  log,
  first,
  last,
}) => {
  if (
    log.content?.checklistItemField === 'is_checked' &&
    log.content.checklistItemFieldNewValue === 'True'
  ) {
    return (
      <ChecklistItemLogCheckedItemFeed log={log} first={first} last={last} />
    );
  }
  if (
    log.content?.checklistItemField === 'is_checked' &&
    log.content.checklistItemFieldNewValue === 'False'
  ) {
    return (
      <ChecklistItemLogUncheckedItemFeed log={log} first={first} last={last} />
    );
  }
  if (log.content?.checklistItemField === 'label') {
    return <ChecklistItemLogRenamedFeed log={log} first={first} last={last} />;
  }

  return (
    <ChecklistItemLogModificationFeed log={log} first={first} last={last} />
  );
};
