import type { BaseObjectRevision } from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import { useTranslation } from 'react-i18next';
import { type IRevisionFeedProps, RevisionFeed } from '../RevisionFeed';

export const CreatedFeed = (
  props: IRevisionFeedProps<BaseObjectRevision>,
): JSX.Element => {
  const { baseObject } = props;
  const { t } = useTranslation();

  return (
    <RevisionFeed {...props}>
      <span className="text-sm font-normal">
        {' '}
        {t('revisions.baseobject.creation', {
          code: baseObject?.code,
        })}
      </span>
    </RevisionFeed>
  );
};
