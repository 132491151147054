import { CategoryLevel } from '@smack/core/api/models/categories/Category';
import { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import { DisplayDashboard } from '@smack/core/components/DataDisplay/DisplayDashboard';
import {
  type ICalendarViewProps,
  type IViewProps,
  RightPanelListIds,
  type View,
  ViewName,
} from '@smack/core/hooks/views/types';
import {
  DetailsSheet,
  type DetailsSheetProps,
} from '@smack/core/views/oldViewsToSort/Views/Objects/DetailsSheet/DetailsSheet';
import {
  BatchUpdateForm,
  type BatchUpdateFormProps,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/BatchUpdateForm/BatchUpdateForm';
import {
  CreationForm,
  type CreationFormProps,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/CreationForm/CreationForm';
import {
  UpdateForm,
  type UpdateFormProps,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/UpdateForm/UpdateForm';
import {
  FullScreen,
  type FullScreenProps,
} from '@smack/core/views/oldViewsToSort/Views/Objects/FullScreen/FullScreen';
import { LinkCalendarType } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/LinkCalendarType';
import { ObjectLinkCalendar } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ObjectLinkCalendar';
import { Tasks } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Tasks';
import { t } from 'i18next';
import { lazy } from 'react';

const KanbanView = lazy(
  () => import('@smack/core/views/oldViewsToSort/Views/Objects/KanBan/KanBan'),
);
const CalendarView = lazy(
  () =>
    import('@smack/core/views/oldViewsToSort/Views/Objects/Calendar/Calendar'),
);
const FunctionalView = lazy(
  () => import('@smack/core/views/oldViewsToSort/Views/Objects/Functional'),
);
const ObjectDataTable = lazy(
  () => import('@smack/core/views/TableView/TableView'),
);
const ObjectMap = lazy(
  () => import('@smack/core/views/oldViewsToSort/Views/Objects/ObjectMap'),
);

export const viewList: View[] = [
  {
    id: ViewName.Map,
    label: t('locationDisplayModal.map'),
    icon: { name: 'map' },
    mobile: true,
    isSelectable: true,
    showTimeline: true,
    categoryMode: CategoryLevel.Set,
    isAvailable: (category: ParentCategory | SubCategory): boolean => {
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayMap ?? false;
      }

      return category?.hasDisplayMap ?? false;
    },
    rightPanel: [],
    leftPanel: ['filter-panel', 'mini-panel'],
    render: (props) => <ObjectMap {...props} />,
  },
  {
    id: ViewName.Calendar,
    label: t('viewList.calendar'),
    icon: { name: 'calendar' },
    mobile: true,
    isSelectable: true,
    showTimeline: false,
    categoryMode: CategoryLevel.Set,
    isAvailable: (category: ParentCategory | SubCategory): boolean => {
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayCalendar ?? false;
      }

      return category?.hasDisplayCalendar ?? false;
    },
    rightPanel: [RightPanelListIds.Stats],
    leftPanel: ['mini-panel', 'filter-panel'],
    render: (props: ICalendarViewProps) => <CalendarView {...props} />,
  },
  {
    id: ViewName.ObjectRoomCalendar,
    label: t('viewList.premisesCalendar'),
    icon: { name: 'calendar-lines' },
    mobile: true,
    isSelectable: true,
    focusedObjectOnly: true,
    showTimeline: false,
    categoryMode: CategoryLevel.Set,
    isAvailable: (category: ParentCategory | SubCategory, object): boolean => {
      if (!object) return false;
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayObjectRoomCalendar ?? false;
      }

      return category?.hasDisplayObjectRoomCalendar ?? false;
    },
    leftPanel: ['mini-panel', 'filter-panel'],
    render: (props: IViewProps) => (
      <ObjectLinkCalendar
        key={ViewName.ObjectRoomCalendar}
        mode={LinkCalendarType.Room}
        {...props}
      />
    ),
  },
  {
    id: ViewName.ObjectCalendar,
    label: t('viewList.objectCalendar'),
    icon: { name: 'calendar-lines' },
    mobile: true,
    isSelectable: true,
    focusedObjectOnly: true,
    showTimeline: false,
    categoryMode: CategoryLevel.Set,
    isAvailable: (category: ParentCategory | SubCategory, object): boolean => {
      if (!object) return false;
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayObjectCalendar ?? false;
      }

      return category?.hasDisplayObjectCalendar ?? false;
    },
    leftPanel: ['mini-panel', 'filter-panel'],
    render: (props: IViewProps) => (
      <ObjectLinkCalendar
        key={ViewName.ObjectCalendar}
        mode={LinkCalendarType.Temporal}
        {...props}
      />
    ),
  },
  {
    id: ViewName.ObjectFunctional,
    label: t('viewList.functional'),
    icon: { name: 'project-diagram' },
    focusedObjectOnly: true,
    isSelectable: true,
    leftPanel: ['filter-panel', 'mini-panel'],
    showTimeline: false,
    categoryMode: CategoryLevel.Set,
    isAvailable: (category: ParentCategory | SubCategory, object): boolean => {
      if (!object) return false;
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayObjectFunctional ?? false;
      }

      return category?.hasDisplayObjectFunctional ?? false;
    },
    rightPanel: [],
    render: (props: IViewProps) => <FunctionalView {...props} />,
  },
  {
    id: ViewName.Table,
    label: t('viewList.table'),
    icon: { name: 'table' },
    showTimeline: true,
    isSelectable: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: (category: ParentCategory | SubCategory): boolean => {
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayTable ?? false;
      }

      return category?.hasDisplayTable ?? false;
    },
    leftPanel: ['set-panel'],
    render: (props: IViewProps) => <ObjectDataTable {...props} />,
  },
  {
    id: ViewName.Kanban,
    label: t('viewList.kanban'),
    icon: { name: 'square-kanban' },
    showTimeline: true,
    isSelectable: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: (category: ParentCategory | SubCategory): boolean => {
      if (category instanceof ParentCategory) {
        return category.hasSubcategoryDisplayKanban ?? false;
      }

      return category?.hasDisplayKanban ?? false;
    },
    leftPanel: ['set-panel'],
    render: (props: IViewProps) => <KanbanView {...props} />,
  },
  {
    id: ViewName.ObjectTask,
    label: t('viewList.objectTask'),
    rightPanel: [RightPanelListIds.Stats],
    icon: { name: 'clipboard-list-check' },
    mobile: true,
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props) => <Tasks key={props.taskId} {...props} />,
  },
  {
    id: ViewName.CreationForm,
    label: t('viewList.creationForm'),
    rightPanel: [],
    icon: { name: 'plus' },
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props: CreationFormProps) => <CreationForm {...props} />,
  },
  {
    id: ViewName.UpdateForm,
    label: t('viewList.updateForm'),
    rightPanel: [],
    icon: { name: 'pen' },
    mobile: true,
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props: UpdateFormProps) => <UpdateForm {...props} />,
  },
  {
    id: ViewName.BatchUpdateForm,
    label: t('viewList.batchUpdateForm'),
    rightPanel: [],
    icon: { name: 'pen' },
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props: BatchUpdateFormProps) => <BatchUpdateForm {...props} />,
  },
  {
    id: ViewName.DetailsSheet,
    label: t('viewList.detailsSheet'),
    rightPanel: [],
    icon: { name: 'pen' },
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props: DetailsSheetProps) => <DetailsSheet {...props} />,
  },
  {
    id: ViewName.Dashboard,
    label: t('viewList.dashboard'),
    rightPanel: [RightPanelListIds.Stats],
    icon: { name: 'chart-pie' },
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props) => <DisplayDashboard {...props} />,
  },
  {
    id: ViewName.FullScreen,
    label: t('viewList.fullScreen'),
    rightPanel: [],
    icon: { name: 'expand' },
    showTimeline: true,
    isSelectable: false,
    focusedObjectOnly: true,
    categoryMode: CategoryLevel.Category,
    isAvailable: () => true,
    render: (props: FullScreenProps) => <FullScreen {...props} />,
  },
];
