import type { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import {
  type ILogFeedProps,
  LogFeed,
} from '@smack/core/views/Feeds/LogsFeed/LogsFeed';
import type React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const TaskLogDeletedChecklistFeed: React.FC<ILogFeedProps<TaskLog>> = (
  props,
) => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'list-check' }}>
      <span className="text-sm font-normal">
        {' '}
        <Trans
          t={t}
          i18nKey="revisions.action.deletedChecklist"
          values={{ checklist: props.log.content?.deletedChecklistLabel }}
        />
      </span>
    </LogFeed>
  );
};
