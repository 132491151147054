import type { Icon } from '@fortawesome/fontawesome-svg-core';
import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IAttributeApiOutput } from '@smack/core/api/models/categories/Attribute/Attribute';
import { User } from '@smack/core/api/models/users/User';
import type { ViewElementType } from '@smack/core/api/models/views/ViewElement/ViewElement';
import {
  type IAttributeValueFromType,
  attributeValueFromType,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import type { IUser } from '@smack/core/store/users/types';

export enum RevisionAction {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
}

export interface IRevisionChangesDetails {
  id: number;
  type?: ViewElementType;
  name: string;
  label: string;
  isMultiple: boolean;
  newValue: IAttributeValueRevision[];
  previousValue: IAttributeValueRevision[];
  element?: IAttributeValueFromType;
}

/**
 *  interface of IBaseObject revision
 *
 * @export
 * @interface IAttributeValueRevision
 */
export interface IAttributeValueRevision {
  id?: number;
  value?: string;
  color?: string;
  icon?: Icon;
  sourceName?: string;
  formattedValue?: string;
}

/**
 *  interface of IBaseObject revision
 *
 * @export
 * @interface IAttributeRevision
 */
export interface IAttributeRevision {
  id: number;
  categoryAttribute: IAttributeApiOutput;
  attributeValueRevisions: IAttributeValueRevision[];
  previousAttributeRevision: {
    id: number;
    categoryAttribute: IAttributeApiOutput;
    attributeValueRevisions: IAttributeValueRevision[];
    baseObjectRevision: Omit<IBaseObjectRevision, 'attributeRevisions'>;
  };
}

/**
 *  interface of IBaseObject revision
 *
 * @export
 * @interface IBaseObjectRevision
 */
export interface IBaseObjectRevision {
  id: number;
  revisionType: RevisionAction;
  createdAt: Date;
  appliedAt: Date;
  user: IUser;
  attributeRevisions?: IAttributeRevision[];
}

/**
 *  class BaseObjectRevision
 *
 * @export
 * @class BaseObjecRevision
 */
export class BaseObjectRevision {
  id: number;
  revisionType: RevisionAction;
  createdAt: Date;
  appliedAt: Date;
  user: IUser;
  attributeRevisions?: IAttributeRevision[];

  /**
   * Creates an instance of BaseObjectRevision.
   * @param {IBaseObjectRevision} revision
   * @memberof BaseObjectRevision
   */
  constructor(revision: IBaseObjectRevision) {
    this.id = revision.id;
    this.revisionType = revision.revisionType;
    this.createdAt = new Date(revision.createdAt);
    this.appliedAt = new Date(revision.appliedAt);
    this.user = revision.user;
    this.attributeRevisions = revision.attributeRevisions;
  }

  /**
   *
    Get the task logs for a baseObject id.
   * @static
   * @param {number} BaseObjectId
   * @return {*}  {Promise<BaseObjectLog[]>}
   * @memberof BaseObjectLog
   */
  static getAllBaseObjectRevisionsById(
    BaseObjectId: number,
  ): Promise<BaseObjectRevision[]> {
    return RESTClient.get<{ data: { results: IBaseObjectRevision[] } }>(
      `/objects/baseobjects/${BaseObjectId}/revisions`,
    ).then((res) => res?.data?.results.map((r) => new BaseObjectRevision(r)));
  }

  /**
   *
    Get the task logs for a baseObject id.
   * @static
   * @param {number} BaseObjectId
   * @return {*}  {Promise<BaseObjectLog[]>}
   * @memberof BaseObjectLog
   */
  static getBaseObjectRevisionById(
    baseObjectId: number,
    revisionId: number,
  ): Promise<BaseObjectRevision> {
    return RESTClient.get<{ data: { results: BaseObjectRevision } }>(
      `/objects/baseobjects/${baseObjectId}/revisions/${revisionId}`,
    ).then((res) => new BaseObjectRevision(res?.data?.results));
  }

  /**
   * Return the mapped changes for the revision details component.
   * @param {BaseObjectRevision} revision
   * @returns {IRevisionChangesDetails[]}
   */
  static mapChangesFromBaseObjectRevisionDetails(
    revision: BaseObjectRevision,
  ): IRevisionChangesDetails[] {
    const allRevisions: IRevisionChangesDetails[] = [];

    if (revision && Array.isArray(revision.attributeRevisions)) {
      for (const rev of revision.attributeRevisions) {
        const {
          categoryAttribute,
          attributeValueRevisions,
          previousAttributeRevision,
        } = rev;
        const entry: IRevisionChangesDetails = {
          id: rev.id,
          name: categoryAttribute.name ?? '',
          label: categoryAttribute.label ?? '',
          isMultiple: categoryAttribute.isMultiple ?? false,
          element: attributeValueFromType.find(
            (d) => d.type === categoryAttribute.type,
          ),
          newValue: [],
          previousValue: [],
        };

        const newRevision = attributeValueRevisions[0];
        const previousRevision =
          previousAttributeRevision?.attributeValueRevisions[0] ?? {};

        if (
          !categoryAttribute.isMultiple &&
          newRevision?.value !== previousRevision?.value
        ) {
          entry.newValue.push(newRevision);
          entry.previousValue.push(previousRevision);
          allRevisions.push(entry);
        } else {
          const currentValues = attributeValueRevisions.map((v) => v);
          const previousValues =
            previousAttributeRevision?.attributeValueRevisions.map((v) => v) ||
            [];

          if (currentValues.length !== previousValues.length) {
            allRevisions.push({
              ...entry,
              newValue: currentValues,
              previousValue: previousValues,
            });
          }
        }
      }
    }
    return allRevisions;
  }

  /**
   * Gets the user object from the revision.
   * @returns {User | undefined}
   */
  getUser(): User | undefined {
    if (this.user) return new User(this.user);
  }
}
