import type { BaseObjectRevision } from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { IconFeed } from '@smack/core/components/DataDisplay/Feeds/IconFeed';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { ModalRevisionDetail } from '@smack/core/src/components/DataDisplay/Modals/ModalRevisionDetail/ModalRevisionDetail';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

/**
 * revision feed components interface
 *
 * @export
 * @interface IRevisionFeedProps
 */
export interface IRevisionFeedProps<T = BaseObjectRevision> {
  revision: T;
  baseObject: BaseObject;
  icon?: IconField;
  first?: boolean;
  last?: boolean;
  hideLine?: boolean;
  children?: JSX.Element;
}

/**
 * A component that displays a feed of revisions.
 * @param {IProps} props - The props for the component.
 * @returns A JSX element.
 */
export const RevisionFeed = (props: IRevisionFeedProps): JSX.Element => {
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const {
    revision,
    baseObject,
    first,
    last,
    icon = { name: 'clipboard-list-check' },
    children,
    hideLine,
  } = props;

  const user = revision.getUser()
    ? revision.getUser()?.getFullName()
    : t('userPanel.hyviloBot');
  const relativeDate = DateUtils.getFromNowText(revision.appliedAt);
  const realDate = DateUtils.getDateString(revision.appliedAt);

  return (
    <div className="w-full h-full">
      <IconFeed
        hideLineBefore={first}
        hideLineAfter={last}
        icon={icon}
        roundColor="#F3F4F6"
        hideLine={hideLine}
        iconColor="gray"
      >
        <div className="py-5 px-2 h-full ">
          <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
            <Trans
              t={t}
              i18nKey="revisions.content"
              values={{ user, relativeDate, realDate }}
              components={{
                primary: <span className="text-text" />,
                small: (
                  <Tooltip
                    title={DateUtils.getDateTimeStringFull(revision.appliedAt)}
                    containerProps={{
                      className: 'inline-flex text-sm font-normal',
                    }}
                  />
                ),
                muted: <span className="pl-1 text-gray-400" />,
                icon: (
                  <span className="inline-flex items-center">
                    &nbsp;
                    <Icon
                      icon={{ name: 'circle-question', familyStyle: 'far' }}
                    />
                    &nbsp;
                  </span>
                ),
                action: <>{Array.isArray(children) ? children : [children]}</>,
              }}
            />
            <span className="ml-2">
              <button
                onClick={() => setOpen(true)}
                type="button"
                className="italic border-b border-gray-400 hover:cursor-pointer text-sm font-normal text-gray-600 dark:text-gray-300"
              >
                {t('revisions.seeDetails')}
              </button>
            </span>
          </span>
        </div>
      </IconFeed>
      <ModalRevisionDetail
        revision={revision}
        baseObject={baseObject}
        onClose={setOpen}
        open={open}
      />
    </div>
  );
};
