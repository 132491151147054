import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { type JSX, useState } from 'react';

interface IFilterBadgeClassNames {
  container?: string;
  label?: string;
  icon?: string;
}

interface Action {
  icon?: IconField;
  onClick?: () => void;
}

export interface IProps {
  label: string;
  labelColor?: string;
  onClick?: () => void;
  actions?: Action[];
  actionsSide?: 'left' | 'right';
  className?: IFilterBadgeClassNames;
  children?: (isActive: boolean) => JSX.Element | undefined;
}

/**
 * Generic Badge Element
 * @param props
 * @returns JSX.Elements
 */
export const FiltersBadge = (props: IProps): JSX.Element => {
  const {
    label,
    onClick,
    actions,
    className,
    children,
    actionsSide = 'right',
  } = props;

  const [isActionActive, setIsActionActive] = useState(false);

  const onHandleActionClic = (action: Action): void => {
    setIsActionActive((previous) => !previous);
    if (action.onClick) action?.onClick();
  };

  const renderActions =
    actions?.map((action, index) => (
      <button
        // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
        key={index}
        onClick={(): void => onHandleActionClic(action)}
        type="button"
        className=" inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-500 hover:bg-gray-300 hover:text-gray-600 focus:outline-none"
      >
        <span className="sr-only">Supprimer</span>
        <Icon
          icon={action.icon ?? { name: 'times' }}
          className={`text-xs text-blue-500 ${className?.icon ?? ''}`}
        />
      </button>
    )) ?? [];

  return (
    <div
      key={label}
      onClick={onClick}
      className={`inline-flex items-center rounded-full bg-blue-100 dark:bg-blue-500 dark:bg-opacity-20 py-0.5 p-1.5 max-w-[350px] gap-1
        ${onClick ? 'cursor-pointer' : ''}
        ${className?.container ?? ''}`}
    >
      {actionsSide === 'left' ? renderActions : null}
      <span
        title={label}
        className={`text-xs text-blue-500 truncate ${className?.label ?? ''}`}
      >
        {label}
      </span>
      {actionsSide === 'right' ? renderActions : null}
      {children?.(isActionActive)}
    </div>
  );
};
