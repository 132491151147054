import {
  Attribute,
  type IAttributeChoice,
  type IAttributeValue,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import type {
  IMediaAttributeValueApiOutputData,
  IMediaAttributeValueInputData,
} from '@smack/core/api/models/medias';
import { File } from '@smack/core/api/models/medias/File';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import type { ViewElement } from '@smack/core/api/models/views/ViewElement/ViewElement';
import { RichTextModal } from '@smack/core/components/DataDisplay/RichText';
import { Url } from '@smack/core/components/DataDisplay/Url/Url';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import type { ParametersValidationSchema } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/ParametersValidationSchema/ParametersValidationSchema';
import type {
  IAttributeFields,
  IProgressBarParams,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import { AttributeType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import type React from 'react';
import type { FieldError } from 'react-hook-form';

export const defaultNoValue = <p className="text-sm text-text "> - </p>;

export const getProgressBarInputColor = (
  progress: number,
  parameters: IProgressBarParams,
): string | undefined => {
  const { greaterThan, equalOrGreaterThan, lessThan, equalOrLessThan } =
    parameters;
  if (greaterThan !== undefined && progress > greaterThan) {
    return parameters.hexColorIfGreaterThan;
  }
  if (equalOrGreaterThan !== undefined && progress >= equalOrGreaterThan) {
    return parameters.hexColorIfEqualOrGreaterThan;
  }
  if (lessThan !== undefined && progress < lessThan) {
    return parameters.hexColorIfLessThan;
  }
  if (equalOrLessThan !== undefined && progress <= equalOrLessThan) {
    return parameters.hexColorIfEqualOrLessThan;
  }
  return undefined;
};

export const addAffixesFromViewElement = (
  node: React.ReactNode,
  viewElement: ViewElement | undefined,
) => {
  if (!viewElement) return node;

  return (
    <>
      {viewElement.prefixNode ? (
        <span className="text-sm flex items-center whitespace-nowrap">
          {viewElement.prefixNode}
        </span>
      ) : null}
      {node}
      {viewElement.suffixNode ? (
        <span className="text-sm flex items-center whitespace-nowrap">
          {viewElement.suffixNode}
        </span>
      ) : null}
    </>
  );
};

export const renderValueAsDeferredModal: IAttributeFields['render'] = (
  attribute,
  attributeValues,
  props,
) => {
  const value: string | undefined =
    attributeValues?.[0]?.formattedValue ?? attributeValues?.[0]?.value;

  if (!value) return defaultNoValue;
  const getFullValue = (): Promise<string> => {
    const baseobjectId = props?.baseObject?.id;

    if (!baseobjectId)
      return Promise.reject(new Error('No baseobject provided'));

    return Attribute.getBaseObjectAttribute(baseobjectId, attribute.id).then(
      (res) => {
        const firstValue = res.values?.at(0);
        if (!firstValue) return '';
        return firstValue.value ?? '';
      },
    );
  };

  if (attribute.type === AttributeType.URL) {
    return (
      <Url src={value} isDeferred={true} getDeferredValue={getFullValue} />
    );
  }

  return (
    <RichTextModal
      title={props?.viewElement?.label ?? ''}
      previewValue={value ?? ''}
      getFullValue={getFullValue}
    />
  );
};

export const getAttributeChoicesAsSelectOption = (
  choices?: IAttributeChoice[],
): Option[] => {
  return (
    choices?.map((att) => {
      return {
        value: att.id,
        label: att.label ?? '',
        color: att.color,
        icon: att.icon,
      };
    }) ?? []
  );
};

export const getAdditionalParametersForAttribute = <T,>(
  inputData: Record<string, unknown> | undefined,
  schema: ParametersValidationSchema<T>,
): Partial<T> => {
  const additionalParameters: Partial<T> = {};
  if (!inputData) return additionalParameters;
  for (const [key, type] of Object.entries(schema)) {
    // If needed in the future, add sanitization here.
    // biome-ignore lint/suspicious/useValidTypeof: FIXME (probably move to Zod here)
    if (typeof inputData[key] === type) {
      additionalParameters[key] = inputData[key];
    } else if (type === 'number') {
      // Try to parse a number
      const parsedNumber = Number(inputData[key]);
      if (!Number.isNaN(parsedNumber)) {
        additionalParameters[key] = parsedNumber;
      }
    }
  }
  return additionalParameters;
};

export const getErrorMessageFromErrorField = (
  error?: FieldError,
): string | undefined => {
  if (!error?.message) return;
  return error.message;
};

export const defaultFormField: IAttributeFields['render'] = (
  attribute,
  attributeValues,
  props,
) => {
  return (
    <Input
      error={getErrorMessageFromErrorField(props?.error)}
      {...props?.controllerProps}
    />
  );
};

export const defaultGetValueForFormField: IAttributeFields['getValueForFormField'] =
  (attribute, attributeValues) => {
    return attributeValues?.[0]?.value;
  };

export const getValueForSelect = (
  attribute: Attribute,
  attributeValues: IAttributeValue[] | undefined,
): Option | Option[] | undefined => {
  const output: Option[] = [];
  attributeValues?.forEach((attributeValue) => {
    if (!attributeValue?.value) return;
    output.push({
      label: attributeValue?.formattedValue
        ? attributeValue?.formattedValue
        : attributeValue?.value || '',
      value:
        (attribute.type === AttributeType.VALUELISTITEM
          ? attributeValue.id
          : attributeValue.value) ?? '',
      color: attributeValue?.color,
      icon: attributeValue?.icon,
    });
  });

  if (!attribute.isMultiple && output) {
    return output[0];
  }
  return output.length ? output : undefined;
};

export const getValueForMedia = (
  attribute: Attribute,
  attributeValues: IAttributeValue[] | undefined,
): IMediaAttributeValueInputData[] | undefined => {
  return attributeValues?.map((v) => {
    const attributeValue = v as unknown as IMediaAttributeValueApiOutputData;
    if (!attributeValue.value) return {};
    return {
      media: attributeValue.value.id,
      mediaFile: new File(attributeValue.value),
    };
  });
};

export const getValueForLinks = (
  attribute: Attribute,
  attributeValues: IAttributeValue[] | undefined,
): InputLink[] | InputLink | undefined => {
  const values = attributeValues?.map((attributeValue) => {
    return {
      id: attributeValue.id,
      linkGroupId: attributeValue.linkGroupId,
      targetBaseobjectId: attributeValue.targetBaseobject?.id,
      targetBaseObjectTitle: attributeValue.targetBaseobject?.title,
      weight: attribute.linkGroup?.isWeighted
        ? attributeValue.weight
        : undefined,
      datetimeRange: attribute.linkGroup?.isTimeSensitive
        ? attributeValue.datetimeRange
        : undefined,
    } as InputLink;
  });
  return attribute.isMultiple ? values : values?.at(0);
};

export const defaultValueToAttributeValues = (value?: unknown): unknown[] => {
  if (!value) return [null];
  if (Array.isArray(value)) return value;
  return [value];
};

export const selectValueToAttributeValues = (value?: Option): unknown[] => {
  if (!value) return [];
  return [value?.value];
};

export const multiselectValueToAttributeValues = (
  value: Option[],
): unknown[] => {
  if (!value) return [];
  return value.map((v) => v.value);
};
