import { useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const TaskLogModificationFeed = (props: ILogFeedProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'clipboard-list-check' }}>
      <span className="text-sm font-normal">
        {' '}
        {t('revisions.action.modifiedTask')}
      </span>
    </LogFeed>
  );
};
