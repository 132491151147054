import type { Comment } from '@smack/core/api/models/comments/Comment';
import type { CommentUpdate } from '@smack/core/api/models/comments/CommentUpdate';
import type { User } from '@smack/core/api/models/users/User';
import { IconFeed } from '@smack/core/components/DataDisplay/Feeds/IconFeed';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { ModalCommentUpdateHistory } from '@smack/core/components/DataDisplay/Modals/ModalCommentUpdateHistory/ModalCommentUpdateHistory';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import { CommentUpdateForm } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CommentUpdateForm';
import { compareAsc } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RichText } from '../../../components/DataDisplay/RichText';
import { ModalFeed } from '../ModalFeed';

/** The maximum number of recipients to display before hiding their names. */
const MAX_RECIPIENTS = 5;

interface IProps {
  comment: Comment;
  first?: boolean;
  last?: boolean;
  editButton: boolean;
  isModified: boolean;
}

/**
 * Icon Feed components for comment
 * @param props
 * @returns
 */
export const CommentFeed = (props: IProps): JSX.Element => {
  const [t] = useTranslation();

  const { comment, first, last, editButton, isModified } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [modified, setModified] = React.useState<boolean>(isModified);
  const [updates, setUpdates] = React.useState<CommentUpdate[]>([]);
  const [isRecipientsModalOpen, setIsRecipientModalOpen] =
    React.useState(false);
  const [recipientTotalCount, setRecipientTotalCount] = React.useState(0);
  const [recipients, setRecipients] = React.useState<User[]>([]);
  const [isRecipientLoading, setIsRecipientLoading] = React.useState(true);

  const submitComment = (content: string): Promise<void> => {
    return comment.updateContent(content).then(() => {
      setModified(true);
      setEdit(false);
    });
  };

  const onOpenRecipientListModal = (): void => {
    setIsRecipientModalOpen(true);
  };

  // Init
  React.useEffect(() => {
    Promise.all([
      comment.getRecipientCount(),
      comment.getPaginatedRecipients(MAX_RECIPIENTS, 0),
    ])
      .then(([count, allRecipients]) => {
        setRecipientTotalCount(count);
        setRecipients(allRecipients);
      })
      .finally(() => setIsRecipientLoading(false));
  }, []);

  return (
    <div className="w-full" aria-label="comment-feed">
      <IconFeed
        hideLineBefore={first}
        hideLineAfter={last}
        hideLine={first && last}
        icon={comment.getUser()?.getUserIcon() || { name: 'user' }}
        roundColor="#F3F4F6"
        iconColor="gray"
      >
        <div className="p-2 m-3 mt-5 border rounded-md min-w-0 w-full">
          {edit ? (
            <CommentUpdateForm
              content={comment.comment || ''}
              onCancel={(): void => {
                setEdit(false);
              }}
              onSubmit={submitComment}
            />
          ) : (
            <div>
              <p className="text-sm font-medium dark:text-gray-200">
                {comment.getUser()?.getFullName()}
              </p>
              <Tooltip
                title={DateUtils.getDateTimeStringFull(comment.createdAt)}
                containerProps={{
                  className: 'inline-flex text-gray-400',
                }}
              >
                <p className="text-xs font-normal text-gray-600 dark:text-gray-300 flex items-center space-x-1">
                  <span>
                    {t('comments.commented')}
                    {DateUtils.getFromNowText(
                      comment.createdAt,
                      '',
                      false,
                      true,
                      t('recurrence.now'),
                    )}
                    &nbsp;{DateUtils.getDateString(comment.createdAt)}
                  </span>
                  <Icon
                    icon={{ name: 'circle-question', familyStyle: 'far' }}
                  />
                </p>
              </Tooltip>
              {modified && (
                <p>
                  <span className="text-xs font-normal text-gray-600 dark:text-gray-300">
                    <button
                      type="button"
                      data-testid="comment-history-button"
                      className="border-b border-gray-400 hover:cursor-pointer"
                      onClick={(): void => {
                        comment.getUpdates().then((res) => {
                          setUpdates(
                            res.sort((a, b) =>
                              compareAsc(a.createdAt, b.createdAt),
                            ),
                          );
                          setOpen(true);
                        });
                      }}
                    >
                      {t('edit.edited')}
                    </button>
                    {comment.getLastModifiedBy() &&
                      ` ${t('byUser', {
                        user: comment.getLastModifiedBy()?.getFullName(),
                      })}`}
                    {DateUtils.getFromNowText(
                      comment.modifiedAt,
                      '',
                      false,
                      true,
                      t('recurrence.now'),
                    )}
                  </span>
                </p>
              )}
              <span className="text-sm mt-2 mb-2 dark:text-gray-200">
                <RichText value={comment.comment ?? ''} />
              </span>
              <p className="text-xs text-gray-400 dark:text-gray-100">
                {isRecipientLoading ? (
                  <LoaderSkeleton height={15} width={300}>
                    <rect x="0" y="0" height={15} width={300} />
                  </LoaderSkeleton>
                ) : (
                  <>
                    {!recipientTotalCount ? (
                      t('comments.recipients.sentNoRecipients')
                    ) : (
                      <>
                        {recipientTotalCount >= MAX_RECIPIENTS && (
                          <>
                            {t('comments.recipients.sent')}
                            &nbsp;
                            <button
                              type="button"
                              className="border-b border-gray-400 dark:border-gray-100 hover:cursor-pointer"
                              onClick={onOpenRecipientListModal}
                            >
                              {t('comments.recipients.peopleWithCount', {
                                count: recipientTotalCount,
                              })}
                            </button>
                          </>
                        )}
                        {recipientTotalCount < MAX_RECIPIENTS && (
                          <>
                            {t('comments.recipients.sent')}
                            &nbsp;
                            {recipients
                              .map((recipient) => recipient.getFullName())
                              .join(', ')}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </p>
              {editButton && (
                <button
                  type="button"
                  data-testid="comment-update-button"
                  onClick={(): void => {
                    setEdit(true);
                  }}
                  className="my-5 rounded-full h-6 w-6 absolute top-2 right-5"
                >
                  <Icon
                    icon={{ name: 'pen', familyStyle: 'fal' }}
                    className="text-xs text-gray-500 dark:text-gray-100"
                  />
                </button>
              )}
              {open && (
                <ModalCommentUpdateHistory
                  comment={comment}
                  updates={updates}
                  onClose={setOpen}
                  open={open}
                />
              )}
              <ModalFeed
                comment={comment}
                recipientCount={recipientTotalCount}
                open={isRecipientsModalOpen}
                onClose={setIsRecipientModalOpen}
              />
            </div>
          )}
        </div>
      </IconFeed>
    </div>
  );
};
