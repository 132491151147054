import type { Checklist as ChecklistModel } from '@smack/core/api/models/tasks/Checklist';
import type { ChecklistItem } from '@smack/core/api/models/tasks/ChecklistItem';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed/ShowIfAllowed';
import { reorderArray } from '@smack/core/utils/Array';
import { ChecklistCreateEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ChecklistBlock/Components/ChecklistCreateEditModal';
import { ChecklistItemCreate } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ChecklistBlock/Components/ChecklistItemCreate';
import { ChecklistItemLine } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ChecklistBlock/Components/ChecklistItemLine';
import { MiniInlineProgressBar } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/MiniInlineProgressBar';
import { TableBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableBlock';
import { TableHeaderAction } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableHeaderAction';
import React from 'react';
import {
  DragDropContext,
  Droppable,
  type OnDragEndResponder,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

interface IProps {
  checklist: ChecklistModel;
  reloadProject: () => Promise<void>;
}

export const ChecklistBlock: React.FC<IProps> = ({
  checklist,
  reloadProject,
}) => {
  const [t] = useTranslation();

  const [isEditing, setIsEditing] = React.useState(false);

  const [optimisticUpdatedItems, setOptimisticUpdatedItems] =
    React.useState<ChecklistItem[]>();

  const items = optimisticUpdatedItems || checklist.items;

  const switchIsEditing = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    setIsEditing((isEditingState) => !isEditingState);
  }, []);

  const deleteChecklist = React.useCallback(() => {
    return checklist.delete().then(reloadProject);
  }, []);

  const [progress, setProgress] = React.useState(checklist.progress);
  const updateProgress = React.useCallback(() => {
    checklist.updateProgress();
    setProgress(checklist.progress);
  }, [checklist]);

  const onDragEnd = React.useCallback<OnDragEndResponder>(
    (result) => {
      // The checklist item has been dragged outside the checklist panel.
      if (!result.destination) return;
      // The checklist item stayed where it was.
      if (result.destination.index === result.source.index) return;
      const sourceItem = checklist.items.at(result.source.index);
      const destinationItem = checklist.items.at(result.destination.index);
      if (!sourceItem || !destinationItem) return;
      setOptimisticUpdatedItems(
        reorderArray(
          items,
          result.source.index,
          // We can assert result.destination is not null because the object will not mutate
          // when the state setter will be called.
          result.destination.index,
        ),
      );
      Promise.all([
        sourceItem.patch({ position: destinationItem.position }),
        destinationItem.patch({ position: sourceItem.position }),
      ])
        .then(reloadProject)
        .finally(() => setOptimisticUpdatedItems(undefined));
    },
    [checklist, items],
  );

  React.useLayoutEffect(updateProgress, [checklist]);

  return (
    <>
      <ChecklistCreateEditModal
        isOpen={isEditing}
        setIsOpen={setIsEditing}
        checklist={checklist}
        reloadProject={reloadProject}
      />
      <TableBlock
        title={checklist.label}
        icon={{ name: 'list-check' }}
        actionButton={
          <>
            <MiniInlineProgressBar progress={progress} className="ml-auto">
              {t('progress', { count: progress })}
            </MiniInlineProgressBar>
            <ShowIfAllowed>
              <TableHeaderAction
                icon={{ name: 'pen' }}
                title={t('edit.edit')}
                onClick={switchIsEditing}
              />
              <TableHeaderAction
                icon={{ name: 'trash-can' }}
                title={t('delete.delete')}
                onClick={deleteChecklist}
              />
            </ShowIfAllowed>
          </>
        }
      >
        <div className="px-4 py-2">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`checklist-${checklist.id}`}>
              {(provided): React.ReactElement => (
                <ul ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((checklistItem, i) => (
                    <ChecklistItemLine
                      key={checklistItem.id}
                      checklistItem={checklistItem}
                      updateProgress={updateProgress}
                      reloadProject={reloadProject}
                      index={i}
                    />
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <ShowIfAllowed>
            <ChecklistItemCreate
              checklist={checklist}
              reloadProject={reloadProject}
            />
          </ShowIfAllowed>
        </div>
      </TableBlock>
    </>
  );
};
