import type { ICommentDataInput } from '@smack/core/api/models/comments/Comment';
import { BaseObjectRevision } from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { AddButton } from '@smack/core/components/Actions/Buttons/Button';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { useCommentsRevisions } from '@smack/core/hooks/useCommentsRevisions/useCommentsRevisions';
import { CommentForm } from '@smack/core/views/oldViewsToSort/Layouts/Forms/CommentForm/CommentForm';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParam } from 'use-query-params';
import { BaseObjectRevisionFeed } from './BaseObjectRevisionFeed';

interface IProps {
  object: BaseObject;
}

/**
 * Comments components for tasks table
 * @param props IProps
 * @returns JSX.Element
 */
export const TrackingPage = (props: IProps): JSX.Element => {
  const { object } = props;
  const [t] = useTranslation();
  const [commentQueryParam, setCommentQueryParam] = useQueryParam<
    string | undefined
  >('comment');
  const [showComments, setShowComments] = React.useState<boolean>(
    object.category?.isDisplayingCommentsByDefault ?? true,
  );
  const [showRevisions, setShowRevisions] = React.useState<boolean>(
    object.category?.isDisplayingLogsByDefault ?? true,
  );
  const [isCommentBoxOpen, setIsCommentBoxOpen] = React.useState(
    !!commentQueryParam,
  );
  const [commentInitialTextValue, setCommentInitialTextValue] =
    React.useState(commentQueryParam);

  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(
    null,
  );

  const toggleIsWritingComment = React.useCallback(() => {
    const open = !isCommentBoxOpen;
    setIsCommentBoxOpen(open);
  }, [isCommentBoxOpen]);

  const getComments = React.useCallback(async () => {
    const comments = await object.commentsManager.getComments();
    if (comments.length === 0) setShowRevisions(true);
    return comments;
  }, [object.id]);

  const getRevisions = React.useCallback(async () => {
    const revisions = await BaseObjectRevision.getAllBaseObjectRevisionsById(
      object.id,
    );
    if (revisions.length === 0) setShowComments(true);
    return revisions;
  }, [object.id]);

  React.useEffect(() => {
    if (commentQueryParam) {
      setCommentQueryParam(undefined);
    }
  }, [commentQueryParam]);

  const [feed, , reload] = useCommentsRevisions({
    getComments,
    getRevisions,
    showRevisions,
    showComments,
    RevisionsComponent: BaseObjectRevisionFeed,
    customEmptyNode: (
      <NoContentMessage
        label={
          !showRevisions || !showComments
            ? t('comments.noCommentsFiltered')
            : t('comments.noComments')
        }
        icon={{ name: 'comment-slash' }}
      />
    ),
    baseObject: object,
  });

  const onCommentSubmit = React.useCallback(
    (data: ICommentDataInput): Promise<void> =>
      object.commentsManager
        .createComment(data)
        .then(() => {
          setIsCommentBoxOpen(false);
        })
        .then(() => reload({ comments: true, revisions: false })),
    [reload],
  );

  return (
    <div className="absolute top-0 bottom-[45px] right-0 left-0">
      <PageHeader
        title={t('baseObjectPanel.followMenu.commentsAndProcess')}
        actionNode={
          <div className="cursor-pointer h-full w-12 text-primary text-xl border-l border-border flex items-center justify-center">
            <DropDown
              menuItems={[
                <CheckboxInput
                  key="comments"
                  label={t('feed.showComments')}
                  name={`task-${object.id}-show-comments`}
                  className={{ container: 'px-2 py-1' }}
                  value={showComments}
                  onChange={setShowComments}
                />,
                <CheckboxInput
                  key="logs"
                  label={t('feed.showLogs')}
                  name={`task-${object.id}-show-logs`}
                  className={{ container: 'px-2 py-1' }}
                  value={showRevisions}
                  onChange={setShowRevisions}
                />,
              ]}
              placement="bottom-end"
            >
              <Tooltip title={t('feed.filter')}>
                <Icon icon={{ name: 'filter' }} />
              </Tooltip>
            </DropDown>
          </div>
        }
      />
      <div
        className="relative flex-grow w-full bg-white dark:bg-neutral-800 h-full border-r"
        ref={setContainerRef}
      >
        <div className="h-full">
          <div className="flex flex-col items-center h-full">
            {Array.isArray(feed) ? (
              <div className="flex w-full flex-col-reverse overflow-y-scroll no-scrollbar">
                {feed}
              </div>
            ) : (
              feed
            )}
            <div className="flex-grow" />
            {object?.isWritable && (
              <div className="w-full bg-view flex border-t border-border  items-center justify-center mx-auto my-0 py-2">
                <AddButton onClick={toggleIsWritingComment}>
                  {t('comments.add')}
                </AddButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <CommentForm
        defaultValue={commentInitialTextValue}
        onQuit={toggleIsWritingComment}
        onSubmit={onCommentSubmit}
        open={isCommentBoxOpen}
        floatingReference={containerRef ?? undefined}
      />
    </div>
  );
};
