import type { IAppProps } from '@smack/core/AppContainer';
import { Loading } from '@smack/core/components/Actions/Loading';
import { PrivateRoute } from '@smack/core/routers/PrivateRoutes';
import { AuthLayout } from '@smack/core/views/oldViewsToSort/Layouts/AuthLayout';
import type React from 'react';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const LoginPage = lazy(
  () =>
    import('@smack/core/views/oldViewsToSort/Pages/Authentication/LoginPage'),
);
const Home = lazy(
  () => import('@smack/core/views/oldViewsToSort/Pages/Home/Home'),
);
const HomeMobile = lazy(
  () => import('@smack/core/views/oldViewsToSort/Pages/HomeMobile'),
);
const NewPasswordPage = lazy(
  () =>
    import(
      '@smack/core/views/oldViewsToSort/Pages/Authentication/NewPasswordPage'
    ),
);
const RedirectPage = lazy(
  () => import('@smack/core/views/oldViewsToSort/Pages/Redirect'),
);
const OpenPage = lazy(
  () => import('@smack/core/views/oldViewsToSort/Pages/Open'),
);

const App: React.FC<IAppProps> = ({ width }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/login" element={<AuthLayout />}>
          <Route index element={<LoginPage initialFormType="login" />} />
          <Route path="reset" element={<LoginPage initialFormType="reset" />} />
        </Route>
        {/* FIXME: merge these two routes */}
        <Route path="/password-set" element={<AuthLayout />}>
          <Route path=":uid/:token/" element={<NewPasswordPage />} />
        </Route>
        <Route path="/password-reset" element={<AuthLayout />}>
          <Route
            path=":uid/:token/"
            element={<NewPasswordPage resetPassword />}
          />
        </Route>
        <Route path="/redirect" element={<RedirectPage />} />
        <Route
          path="/open-file/:uuid"
          element={
            <PrivateRoute>
              <OpenPage type="File" />
            </PrivateRoute>
          }
        />
        <Route
          path="/open-folder/:uuid"
          element={
            <PrivateRoute>
              <OpenPage type="Folder" />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              {width <= 800 ? <HomeMobile /> : <Home />}
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default App;
