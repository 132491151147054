import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button } from '@smack/core/components/Actions/Buttons/Button';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import Section from '@smack/core/components/Table/ColumnManager/Section';
import type { ColumnManagerProps } from '@smack/core/components/Table/ColumnManager/types';
import {
  formatDataForDndKit,
  getAccessorKeys,
} from '@smack/core/components/Table/ColumnManager/utils';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ColumnManager = ({
  columns,
  preferences,
  setPreferences,
}: ColumnManagerProps) => {
  const [t] = useTranslation();
  const [data, setData] = useState(
    formatDataForDndKit(columns, preferences?.columnOrder ?? []),
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // means that the pointer must move at least 8 pixels before being activated.
      },
    }),
    useSensor(KeyboardSensor),
  );

  const onPinningChange = (columnId: string, position: 'left' | 'right') => {
    const prevPinnedColumns = preferences?.columnPinning ?? {
      left: [],
      right: [],
    };
    const newPinnedColumns = { ...prevPinnedColumns };
    const isAlreadyPinned = newPinnedColumns[position].includes(columnId);

    if (isAlreadyPinned) {
      // unpin it
      newPinnedColumns[position] = newPinnedColumns[position].filter(
        (id) => id !== columnId,
      );
    } else {
      const oppositePosition = position === 'left' ? 'right' : 'left';
      newPinnedColumns[oppositePosition] = newPinnedColumns[
        oppositePosition
      ].filter((id) => id !== columnId);
      newPinnedColumns[position].push(columnId);
    }

    setPreferences({
      ...preferences,
      tableShowSectionHeaders: false,
      columnPinning: newPinnedColumns,
    });
  };

  const onColumnOrderChange = (newColumnOrder) => {
    setPreferences({
      ...preferences,
      columnOrder: newColumnOrder,
    });
  };

  const handleShowSections = (value): void => {
    setPreferences({
      ...preferences,
      tableShowSectionHeaders: value,
      columnPinning: value
        ? { left: [], right: [] }
        : preferences?.columnPinning,
    });
  };

  const onCheckboxChange = (
    names: string[],
    shouldBeVisible: boolean,
  ): void => {
    const hiddenColumns = preferences?.hiddenColumns ?? [];
    let newHiddenColumns: string[];
    if (shouldBeVisible) {
      newHiddenColumns = Array.from(
        new Set([...(hiddenColumns ?? []), ...names]),
      );
    } else {
      newHiddenColumns = hiddenColumns.filter(
        (hiddenColumn) => !names.includes(hiddenColumn),
      );
    }
    setPreferences({
      ...preferences,
      hiddenColumns: newHiddenColumns,
    });
  };

  useEffect(() => {
    setData(formatDataForDndKit(columns, preferences?.columnOrder ?? []));
  }, [columns, preferences?.columnOrder]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const activeSectionIndex = data.findIndex((section) =>
      section.items.some((item) => item.id === active.id),
    );
    const overSectionIndex = data.findIndex((section) =>
      section.items.some((item) => item.id === over.id),
    );

    // Handle section-level reordering
    if (activeSectionIndex === -1 && overSectionIndex === -1) {
      setData((items) => {
        const oldIndex = items.findIndex((section) => section.id === active.id);
        const newIndex = items.findIndex((section) => section.id === over.id);
        const reorderedSections = arrayMove(items, oldIndex, newIndex);

        onColumnOrderChange(getAccessorKeys(reorderedSections));

        return reorderedSections;
      });
    }

    // Handle column-level reordering
    if (activeSectionIndex === overSectionIndex) {
      const activeItemIndex = data[activeSectionIndex].items.findIndex(
        (item) => item.id === active.id,
      );
      const overItemIndex = data[activeSectionIndex].items.findIndex(
        (item) => item.id === over.id,
      );

      if (activeItemIndex >= 0 && overItemIndex >= 0) {
        const updatedItems = arrayMove(
          data[activeSectionIndex].items,
          activeItemIndex,
          overItemIndex,
        );

        setData((prev) => {
          const reorderedSections = prev.map((section, index) =>
            index === activeSectionIndex
              ? { ...section, items: updatedItems }
              : section,
          );

          onColumnOrderChange(getAccessorKeys(reorderedSections));

          return reorderedSections;
        });
      }
    }
  };

  return (
    <DropDown
      data-testid="column-manager-component"
      menuItems={[
        <Fragment key="element-1">
          <CheckboxInput
            id="table-show-section-headers"
            data-testid="table-show-section-headers"
            key="table:show-section-headers"
            value={preferences?.tableShowSectionHeaders ?? false}
            className={{
              container:
                'relative flex items-start p-2 border-dashed border-b w-full min-w-[430px]',
              label: 'text-[16px] text-text',
            }}
            label={t('objectTable.showSectionHeaders')}
            onChange={handleShowSections}
          />

          <DndContext
            key="section-dnd"
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={data.map((section) => section.id)}
              strategy={verticalListSortingStrategy}
            >
              {data.map((section) => (
                <Section
                  key={section.id}
                  section={section}
                  hiddenColumns={preferences?.hiddenColumns ?? []}
                  columnPinning={
                    preferences?.columnPinning ?? { left: [], right: [] }
                  }
                  onPinningChange={onPinningChange}
                  onDragEnd={handleDragEnd}
                  onCheckboxChange={onCheckboxChange}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Fragment>,
      ]}
    >
      <Button>
        <Icon icon={{ name: 'line-columns' }} className="mr-1" />
        {t('objectTable.columns')}
        <Icon icon={{ name: 'chevron-down' }} className="ml-3" />
      </Button>
    </DropDown>
  );
};

export default ColumnManager;
