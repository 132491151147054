import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { CustomRecurrenceModal } from '@smack/core/components/DataInput/RecurrenceInput/Components/CustomRecurrenceModal/CustomRecurrenceModal';
import { SelectRecurrenceInput } from '@smack/core/components/DataInput/SelectRecurrenceInput';
import { RRuleUtils } from '@smack/core/utils/RRule';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RRule, RRuleSet, rrulestr } from 'rrule';

interface IProps {
  value?: string;
  dtStart?: Date;
  onChange?: (val?: string) => void;
  includeDtStart?: boolean;
  allowEmpty?: boolean;
}

export const RRuleGenerator: React.FC<IProps> = ({
  value,
  onChange,
  dtStart,
  includeDtStart = true,
  allowEmpty = true,
}) => {
  const [t] = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);

  const computedRRuleSetValue = React.useMemo<RRuleSet | undefined>(() => {
    if (!value) return undefined;
    try {
      return rrulestr(value, {
        forceset: true,
      }) as RRuleSet;
    } catch {
      return undefined;
    }
  }, [value]);

  /**
   * handle input change
   *
   * @param {RRule} val
   */
  const handleChange = (val?: RRule): void => {
    if (onChange && val) {
      if (dtStart && includeDtStart) {
        val.origOptions.dtstart = dtStart;
      }
      const set = new RRuleSet();
      set.rrule(val);
      computedRRuleSetValue?.exdates()?.forEach((exDate) => {
        set.exdate(exDate);
      });
      onChange(set.toString());
    } else if (onChange && typeof val === 'undefined') {
      onChange(undefined);
    }
  };

  function determineQuickRRule(val?: string): string {
    if (!val) return RRuleUtils.staticQuickRRules(dtStart)[0].value;
    const parsedRRule = /RRULE:.+/m.exec(val);
    if (!parsedRRule) return RRuleUtils.staticQuickRRules(dtStart)[0].value;
    const foundOption = RRuleUtils.staticQuickRRules(dtStart).find(
      (opt) => parsedRRule[0] === opt.value,
    );

    if (foundOption) {
      return foundOption.value;
    }
    return RRuleUtils.staticQuickRRules(dtStart).slice(-1)[0].value;
  }

  const onQuickRRuleChange = (newRRule?: string): void => {
    const newValue = newRRule || RRuleUtils.staticQuickRRules(dtStart)[0];
    if (typeof newValue === 'string') {
      if (newValue === 'NORRULE') {
        handleChange(); // undefined
      } else if (newValue !== 'CUSTOM') {
        handleChange(rrulestr(newValue));
      } else {
        setModalOpen(true);
      }
    }
  };

  React.useEffect(() => {
    if (modalOpen && !value) {
      handleChange(new RRule({ freq: RRule.DAILY }));
    }
  }, [modalOpen]);

  const getValue = (): string => {
    if (!value) return RRuleUtils.staticQuickRRules(dtStart)[0].value;
    const isCustom = determineQuickRRule(value) === 'CUSTOM';
    if (isCustom) {
      return value;
    }
    return determineQuickRRule(value);
  };

  return (
    <div className="flex items-center ">
      <Icon
        icon={{ name: 'repeat', familyStyle: 'fal' }}
        className={'text-gray-500 text-lg mr-2'}
      />
      <div className=" w-full max-w-[400px]">
        {dtStart && (
          <SelectRecurrenceInput
            value={getValue()}
            onChange={onQuickRRuleChange}
            dtStart={dtStart}
            allowEmpty={allowEmpty}
          />
        )}
      </div>
      <CustomRecurrenceModal
        open={modalOpen}
        setOpen={setModalOpen}
        computedRRuleSetValue={computedRRuleSetValue}
        handleChange={handleChange}
      />
    </div>
  );
};
