import {
  type Batch,
  type BatchItem,
  FILE_STATES,
  UPLOADER_EVENTS,
} from '@rpldy/uploady';
import type { IMediaAttributeValueInputData } from '@smack/core/api/models/medias';
import type { ITemporaryFile } from '@smack/core/api/models/medias/TemporaryFile/TemporaryFile';
import { useDeferredPromise } from '@smack/core/utils/DeferedPromise';
import { useBaseObjectViewContextContext } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/context/BaseObjectViewContext';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { UploadProgressBar } from '../../Actions/UploadProvider/UploadProgressBar/UploadProgressBar';
import {
  type IUploadProviderProps,
  UploadDestinationType,
  UploadProvider,
} from '../../Actions/UploadProvider/UploadProvider';
import { FileList, type IFileItem } from '../../DataDisplay/FileList';
import { CheckboxInput } from '../CheckboxInput';
import {
  ResolutionChoice,
  ResolutionModal,
} from './Components/ResolutionModal/ResolutionModal';

export interface IMediaInputParameters {
  resizeImage?: boolean;
  resizeImageSizeThreshold?: string;
  selectImageResolution?: boolean;
  minImageResolution?: string;
  midImageResolution?: string;
  maxImageResolution?: string;
}

export interface IMediaInputProps {
  value?: IMediaAttributeValueInputData[];
  onChange?: (value: IMediaAttributeValueInputData[] | null) => void;
  error?: string;
  categoryId?: number;
  multiple?: boolean;
  parameters?: IMediaInputParameters;
  uploadProviderProps?: IUploadProviderProps;
  children: JSX.Element;
}

/**
 * Input for medias
 * @param props IProps
 * @returns JSX.ELEMENT
 */
export const MediaInput = (props: IMediaInputProps): JSX.Element => {
  const {
    value,
    onChange,
    error,
    categoryId,
    multiple,
    parameters,
    uploadProviderProps,
    children,
  } = props;

  const { setAreActionButtonsReady } = useBaseObjectViewContextContext();
  const [isImageResizeEnabled, setIsImageResizeEnabled] = React.useState(
    parameters?.resizeImage ?? true,
  );
  const [resolutionModalOpen, setResolutionModalOpen] =
    React.useState<boolean>(false);
  const { defer: resolutionPromise, deferRef: resolutionPromiseRef } =
    useDeferredPromise<
      | {
          width: number;
          height: number;
        }
      | undefined
    >();
  const [temporaryFiles, setTemporaryFiles] = React.useState<ITemporaryFile[]>(
    [],
  );
  const { t } = useTranslation();

  const onTemporaryFileUploadsFinished = (data: ITemporaryFile[]) => {
    setTemporaryFiles([...temporaryFiles, ...data]);
    onChange?.([
      ...(value ?? []),
      ...data.map((temporaryFile) => {
        return {
          temporaryFileId: temporaryFile.id,
        };
      }),
    ]);
  };

  /**
   * handle the deletion of the file
   *
   * @param {number} index
   */
  const onDeleteFile = (index: number): void => {
    if (value && onChange) {
      const newval = value;
      newval.splice(index, 1);
      onChange([...newval]);
    }
  };

  const listValues = React.useMemo((): IFileItem[] => {
    const listValues: IFileItem[] = [];
    if (!value) return listValues;

    for (const item of value) {
      if (item.mediaFile) {
        // existing file
        listValues.push({
          label: item.mediaFile.label,
          icon: item.mediaFile.mimetype?.icon,
          onClick: item.mediaFile.open.bind(item.mediaFile),
        });
      } else if (item.temporaryFileId) {
        // uploaded temporary file
        const temporaryFile = temporaryFiles.find(
          (file) => file.id === item.temporaryFileId,
        );
        if (!temporaryFile) continue;
        listValues.push({
          label: temporaryFile.filename,
          onClick: () => window.open(temporaryFile.filepath, '_blank'),
        });
      }
    }
    return listValues;
  }, [value]);

  const parseImageResolution = (resolution: string | undefined) => {
    if (!resolution) return;
    if (resolution === '0') {
      return { width: 0, height: 0 };
    }
    const dims = resolution.split('x');
    return {
      width: Number.parseInt(dims[0]),
      height: Number.parseInt(dims[1]),
    };
  };

  const promptImageResolutionChoice = React.useCallback((): Promise<
    | {
        width: number;
        height: number;
      }
    | undefined
  > => {
    if (!parameters?.selectImageResolution) {
      return Promise.resolve(
        parseImageResolution(parameters?.resizeImageSizeThreshold),
      );
    }

    if (resolutionPromiseRef) {
      // already prompted once, reuse the result
      return resolutionPromiseRef.promise;
    }
    setResolutionModalOpen(true);
    return resolutionPromise().promise;
  }, [parameters, resolutionPromiseRef]);

  const onImageResolutionChoice = (resolutionChoice: ResolutionChoice) => {
    let resolution: string | undefined;
    if (resolutionChoice === ResolutionChoice.MIN) {
      resolution = parameters?.minImageResolution;
    } else if (resolutionChoice === ResolutionChoice.MAX) {
      resolution = parameters?.maxImageResolution;
    } else {
      resolution = parameters?.midImageResolution;
    }
    resolutionPromiseRef?.resolve(parseImageResolution(resolution));
  };

  return (
    <div className="w-full max-w-lg">
      {parameters?.selectImageResolution ? (
        <ResolutionModal
          open={resolutionModalOpen}
          setOpen={setResolutionModalOpen}
          onSelect={onImageResolutionChoice}
        />
      ) : null}
      {listValues.length > 0 && (
        <FileList files={listValues} onDelete={onDeleteFile} />
      )}
      {((!multiple && !value?.length) || multiple) && (
        <UploadProvider
          uploadDestinationType={UploadDestinationType.TEMPORARY_FILE}
          resizeImageOptions={{
            resizeImage: isImageResizeEnabled,
            resizeImageThresholdSize: parseImageResolution(
              parameters?.resizeImageSizeThreshold,
            ),
            resizeImageTargetSize: promptImageResolutionChoice,
          }}
          multiple={multiple}
          params={{ categoryId }}
          listeners={{
            [UPLOADER_EVENTS.BATCH_START]: () => {
              setAreActionButtonsReady?.(false);
            },
            [UPLOADER_EVENTS.BATCH_FINISH]: (batch: Batch) => {
              const temporaryFiles: ITemporaryFile[] = [];

              const showErrorToast = (item: BatchItem) => {
                const errorMessageKey =
                  item.uploadResponse.data?.errors?.[0]?.code === 'malicious'
                    ? 'medias.uploadFileSecurityError'
                    : 'medias.uploadFileError';
                toast.error(t(errorMessageKey, { label: item.file.name }));
              };

              const handleItemState = (item: BatchItem) => {
                if (item.state === FILE_STATES.ERROR) {
                  showErrorToast(item);
                } else if (item.state === FILE_STATES.FINISHED) {
                  temporaryFiles.push(item.uploadResponse.data.results);
                }
              };

              batch.items.forEach(handleItemState);

              if (temporaryFiles) {
                onTemporaryFileUploadsFinished(temporaryFiles);
              }
              setAreActionButtonsReady?.(true);
            },
          }}
          {...uploadProviderProps}
        >
          <div className="flex flex-col gap-2">
            {children}
            <CheckboxInput
              label={t('medias.upload.resizeImage')}
              value={isImageResizeEnabled}
              onChange={setIsImageResizeEnabled}
              className={{ label: 'text-xs' }}
            />
            <UploadProgressBar unmountOnFinished />
          </div>
        </UploadProvider>
      )}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};
