import {
  BaseObjectRevision,
  type IRevisionChangesDetails,
} from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { CloseButtonPrimary } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { t } from 'i18next';
import React, { type Dispatch, type SetStateAction } from 'react';
import { Trans } from 'react-i18next';
import { RevisionDetails } from './RevisionDetails';

interface IProps {
  revision: BaseObjectRevision;
  baseObject: BaseObject;
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

export const ModalRevisionDetail = (props: IProps): JSX.Element => {
  const { revision, baseObject, open, onClose } = props;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [revisionDetails, setRevisionDetails] = React.useState<
    IRevisionChangesDetails[]
  >([]);

  const getRevision = React.useCallback(async () => {
    const revisionDetails = await BaseObjectRevision.getBaseObjectRevisionById(
      baseObject.id,
      revision.id,
    );
    return revisionDetails;
  }, [baseObject.id]);

  React.useEffect(() => {
    if (open) {
      getRevision().then((rev) => {
        const mappedChanges =
          BaseObjectRevision.mapChangesFromBaseObjectRevisionDetails(rev);
        setLoading(false);
        setRevisionDetails(mappedChanges);
      });
    }
  }, [open, getRevision]);

  return (
    <div data-testid="revision-history-modal">
      <Modal
        title={t('revisions.modalTitle', { objectLabel: baseObject.label })}
        description={
          <Trans
            i18nKey="revisions.modalDescription"
            values={{
              user: revision.getUser()?.getFullName(),
              date: DateUtils.getDateTimeStringFull(revision.appliedAt),
            }}
            components={{ strong: <strong /> }}
          />
        }
        icon={{ name: 'clock-rotate-left' }}
        color="#dbeafe"
        iconColor="#60a5fa"
        open={open}
        onClose={onClose}
      >
        <div
          data-testid="revision-history-modal-content"
          className="w-[45vw] md:w-[45vw] sm:w-[45vw]"
        >
          <RevisionDetails
            loading={loading}
            baseObject={baseObject}
            revisionDetails={revisionDetails}
          />
        </div>
        <div className="flex items-center justify-end gap-3 mt-3">
          <CloseButtonPrimary onClick={(): void => onClose(false)} />
        </div>
      </Modal>
    </div>
  );
};
