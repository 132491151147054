import { User } from '@smack/core/api/models/users/User';
import {
  CancelButton,
  SendButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { MultipleEmailInput } from '@smack/core/components/DataInput/MultipleEmailInput/EmailMultiInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { TextAreaInput } from '@smack/core/components/DataInput/TextAreaInput';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: MessageFormOutput) => void;
}

export interface MessageFormOutput {
  subject: string;
  message?: string;
  userRecipients: Option<number>[];
  emailRecipients: string[];
}

export const ModalNotificationEmailForm = (props: IProps): JSX.Element => {
  const { open, onClose, onSubmit } = props;
  const [t] = useTranslation();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<MessageFormOutput>();
  const [searchUser, setSearchUser] = useState<string>('');

  const [userSearchOptions, setUserSearchOptions] = useState<Option[]>([]);
  const [isUserInfiniteScrollOver, setIsUserInfiniteScrollOver] =
    useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchUser)
      User.getUsers(searchUser, 10).then((users) => {
        setUserSearchOptions(users.map((u) => u.toOption()));
        setIsUserInfiniteScrollOver(users.length === 0);
      });
  }, [searchUser]);

  const handleUserInfiniteScroll = () => {
    if (!isUserInfiniteScrollOver) {
      setIsUserLoading(true);
      User.getUsers(searchUser, 10, undefined, userSearchOptions.length).then(
        (users) => {
          setUserSearchOptions([
            ...userSearchOptions,
            ...users.map((u) => u.toOption()),
          ]);
          setIsUserInfiniteScrollOver(users.length === 0);
          setIsUserLoading(false);
        },
      );
    }
  };

  const validateRecipients = () => {
    const values = getValues();
    return (
      (values.userRecipients && values.userRecipients.length > 0) ||
      (values.emailRecipients && values.emailRecipients.length > 0)
    );
  };

  return (
    <Modal
      icon={{ name: 'paper-plane' }}
      title={t('notification.emailForm.title')}
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1">
          <Controller
            control={control}
            name="subject"
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                label={t('notification.emailForm.subject')}
                placeholder={t('notification.emailForm.subjectPlaceholder')}
                size={'fluid'}
              />
            )}
          />
          <Controller
            control={control}
            name="userRecipients"
            defaultValue={[]}
            rules={{ validate: validateRecipients }}
            render={({ field }): JSX.Element => (
              <SelectInput
                {...field}
                multiple
                label={t('notification.emailForm.userRecipients')}
                onSearch={setSearchUser}
                onLastOptionVisible={handleUserInfiniteScroll}
                options={userSearchOptions}
                isLoading={isUserLoading}
                needFilter={false}
                className="w-full"
              />
            )}
          />
          <Controller
            control={control}
            name="emailRecipients"
            defaultValue={[]}
            rules={{ validate: validateRecipients }}
            render={({ field }): JSX.Element => (
              <MultipleEmailInput
                {...field}
                label={t('notification.emailForm.emailRecipients')}
                className="w-full"
              />
            )}
          />
          {errors.userRecipients || errors.emailRecipients ? (
            <p className="text-red-500">
              {t('notification.emailForm.missingRecipientsError')}
            </p>
          ) : null}
          <Controller
            control={control}
            name="message"
            defaultValue=""
            render={({ field }) => (
              <TextAreaInput
                {...field}
                label={t('notification.emailForm.message')}
              />
            )}
          />
          <div className="flex items-center justify-end mt-2 gap-3">
            <CancelButton onClick={(): void => onClose()} />
            <SendButton type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );
};
