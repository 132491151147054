import {
  CloseButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import { t } from 'i18next';
import { type Frequency, RRule, type RRuleSet } from 'rrule';
import { getRRuleNumber, getRecurenceTypeOptions } from '../../Utils';
import { EndRRule } from '../EndRRule';
import { MonthlyRRule } from '../MonthlyRRule';
import { WeeklyRRule } from '../WeeklyRRule';

interface CustomRecurrenceModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  computedRRuleSetValue?: RRuleSet;
  handleChange: (val?: RRule) => void;
  noEndRule?: boolean;
}

export const CustomRecurrenceModal: React.FC<CustomRecurrenceModalProps> = ({
  open,
  setOpen,
  computedRRuleSetValue,
  handleChange,
  noEndRule = false,
}) => {
  /**
   * get the components from the repeat value
   */
  const switchRecurenceType = (): React.ReactNode | undefined => {
    switch (computedRRuleSetValue?.rrules().at(0)?.options?.freq) {
      case RRule.YEARLY:
        return null;
      case RRule.MONTHLY:
        return (
          <MonthlyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.WEEKLY:
        return (
          <WeeklyRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        );
      case RRule.DAILY:
        return null;
    }
  };

  return (
    <Modal
      icon={{ name: 'repeat' }}
      title={t('recurrence.recurrenceModalTitle')}
      open={open}
      onClose={setOpen}
    >
      <div
        className={`relative flex flex-col min-w-[785px] ${!noEndRule ? 'h-[400px]' : 'h-[230px]'}`}
      >
        <div className="my-4 min-w-[785px]">
          <div className="flex items-center gap-5 whitespace-nowrap ">
            <p className="text-sm">{t('recurrence.repeatEvery')}</p>
            <NumberInput
              size="xSmall"
              min={1}
              value={getRRuleNumber(
                computedRRuleSetValue?.rrules().at(0)?.options?.interval,
              )}
              onChange={(val): void =>
                handleChange(
                  new RRule({
                    ...computedRRuleSetValue?.rrules().at(0)?.origOptions,
                    interval: Number.parseInt(val.toString()),
                  }),
                )
              }
            />
            <SelectInput
              className="w-32"
              isClearable={false}
              isSearchable={false}
              value={getRecurenceTypeOptions().find(
                (v) =>
                  v.value ===
                  computedRRuleSetValue?.rrules().at(0)?.options?.freq,
              )}
              onChange={(data): void =>
                handleChange(
                  new RRule({
                    freq: data?.value as Frequency,
                  }),
                )
              }
              options={getRecurenceTypeOptions()}
            />
          </div>
        </div>
        {switchRecurenceType()}
        {!noEndRule ? (
          <EndRRule
            value={computedRRuleSetValue?.rrules().at(0)}
            onChange={handleChange}
          />
        ) : null}
        <div className="absolute bottom-0 right-0 flex items-center justify-end gap-3 mt-3">
          <CloseButton onClick={(): void => setOpen(false)} />
          <SaveButton onClick={(): void => setOpen(false)} />
        </div>
      </div>
    </Modal>
  );
};
