import type { ChecklistLog } from '@smack/core/api/models/tasks/ChecklistLog';
import { Trans, useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const ChecklistLogModificationFeed = (
  props: ILogFeedProps<ChecklistLog>,
): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'list-check' }}>
      <span className="text-sm font-normal">
        {' '}
        <Trans
          t={t}
          i18nKey="revisions.action.modifiedChecklist"
          values={{ checklist: props.log.content?.checklist?.label }}
        />
      </span>
    </LogFeed>
  );
};
