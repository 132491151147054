import type { BaseObjectRevision } from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import { useTranslation } from 'react-i18next';
import { type IRevisionFeedProps, RevisionFeed } from '../RevisionFeed';

export const UpdatedFeed = (
  props: IRevisionFeedProps<BaseObjectRevision>,
): JSX.Element => {
  const { t } = useTranslation();
  return (
    <RevisionFeed {...props}>
      <span className="text-sm font-normal">
        {' '}
        {t('revisions.baseobject.update', { code: '' })}
      </span>
    </RevisionFeed>
  );
};
