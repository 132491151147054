import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import {
  AttributeValue,
  type AttributeValueApiOutput,
} from '@smack/core/api/models/categories/AttributeValue/AttributeValue';
import {
  type ILinkGroup,
  LinkGroup,
} from '@smack/core/api/models/categories/LinkGroup';
import type { IMimetype } from '@smack/core/api/models/medias/Mimetype';
import type { Link } from '@smack/core/api/models/objects/Link/Link';
import type { IBaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import {
  type AttributeType,
  IconByAttributeType,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import {
  FiltersQueryParams,
  type IFilters,
  getParamsFromFilters,
} from '@smack/core/utils/Filters';
import type { AxiosResponse } from 'axios';

export type FormValue = string | number | boolean | undefined | Option;

export type FormValues = FormValue | FormValue[];

export interface IAttributeValue<T = string> {
  id?: string | number;
  value?: T;
  formattedValue?: string;
  frontEndpoint?: string;
  deferredValue?: boolean;
  uri?: string;
  icon?: IconField;
  color?: string;
  datetimeRange?: Link['datetimeRange'];
  descriptorBaseobject?: IBaseObject;
  targetBaseobject?: IBaseObject;
  linkGroupId?: number;
  weight?: number;
}

export interface IAttributeChoice {
  id: string | number;
  label?: string | number;
  color?: string;
  icon?: IconField;
}

export interface IAttributeApiOutput {
  id: number;
  name?: string;
  label: string;
  sourceName: string;
  choices?: IAttributeChoice[];
  type: AttributeType;
  values?: IAttributeValue[];
  isMultiple?: boolean;
  isRequired?: boolean;
  icon?: IconField;
  isSortable?: boolean;
  isFilterable?: boolean;
  linkGroupId?: number;
  linkGroup?: ILinkGroup;
  filterOptions?: string[];
  allowedMimetypes?: IMimetype[];
}

export class Attribute {
  id: number;

  label: string;

  sourceName: string;

  type: AttributeType;

  values?: IAttributeValue[];

  isRequired?: boolean;

  isMultiple?: boolean;

  choices?: IAttributeChoice[];

  icon?: IconField;

  isSortable?: boolean;

  isFilterable?: boolean;

  filterOptions?: string[];

  _linkGroupId?: number;

  linkGroup?: LinkGroup;

  allowedMimetypes?: IMimetype[];

  constructor(data: IAttributeApiOutput) {
    this.id = data.id;
    this.label = data.label;
    this.sourceName = data.sourceName;
    this.type = data.type;
    this.values = data.values;
    this.isRequired = data.isRequired;
    this.isMultiple = data.isMultiple;
    this.icon = data.icon;
    this.choices = data.choices;
    this.isSortable = data.isSortable;
    this.isFilterable = data.isFilterable;
    this.filterOptions = data.filterOptions;
    this._linkGroupId = data.linkGroupId;
    this.linkGroup = data.linkGroup ? new LinkGroup(data.linkGroup) : undefined;
    this.allowedMimetypes = data.allowedMimetypes;
  }

  get linkGroupId() {
    return this._linkGroupId ?? this.linkGroup?.id;
  }

  get iconFromType(): IconField {
    return { name: IconByAttributeType[this.type] ?? 'question-circle' };
  }

  shouldRenderMultipleValues(values?: IAttributeValue[]): boolean {
    if (!this.isMultiple) return false;
    if (!values) return false;
    return values?.length > 1;
  }

  static getByTypeAndCategoryId = async (
    categoryId: number,
    type?: AttributeType,
  ): Promise<Attribute[]> => {
    const res = await RESTClient.get<{
      data: { results: IAttributeApiOutput[] };
    }>(
      `/categories/categories/${categoryId}/attributes${
        type ? `?type=${type}` : ''
      }`,
    );
    return res.data.results.map((r) => new Attribute(r));
  };

  static getAttributeValuesFromCategoryId = async (
    categoryAttributeId: number,
    limit = 10,
    offset = 0,
    search?: string,
    filters?: IFilters,
  ): Promise<IPagination<AttributeValueApiOutput>> => {
    const newFilters = filters ?? {};
    if (newFilters?.attributes) {
      delete newFilters.attributes[categoryAttributeId];
    }
    const res = await RESTClient.get<
      AxiosResponse<IPagination<AttributeValueApiOutput>>
    >(
      `/objects/attribute-values?unique=true&category-attribute=${categoryAttributeId}&limit=${limit}&offset=${offset}&q=${
        search ?? ''
      }`,
      getParamsFromFilters(newFilters ?? {}, [
        FiltersQueryParams.ATTRIBUTE_FILTER,
        FiltersQueryParams.DATE_RANGE,
      ]),
    );
    return {
      ...res.data,
      results: res.data.results.map((value) => new AttributeValue(value)),
    };
  };

  static getBaseObjectAttribute(
    baseobjectId,
    categoryAttributeId: number,
  ): Promise<Attribute> {
    return RESTClient.get<AxiosResponse<{ results: IAttributeApiOutput }>>(
      `/objects/baseobjects/${baseobjectId}/attributes/${categoryAttributeId}`,
    ).then((res) => new Attribute(res.data.results));
  }
}
