import { MediasManager } from '@smack/core/api/models/medias';
import { File as FileModel } from '@smack/core/api/models/medias/File';
import { MediaCategory } from '@smack/core/api/models/medias/MediaCategory';
import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { Loader } from '@smack/core/components/Actions/Loader';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { SearchBar } from '@smack/core/components/DataInput/SearchBar';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import { checkPathExists } from '@smack/core/utils/ElectronUtils';
import { BaseObjectPanelContext } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/BaseObjectPanel';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { TableHeader } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableHeader';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageThumbnail } from '../../ImageThumbnail/ImageThumbnail';

interface IProps {
  parentObject: MediasManager;
  title: string;
  className?: string;
  legacyHeader?: boolean; // if true, use the header originaly implemented for the BaseObjectPanel
}

/**
 * Return ImageMediasGrid Components
 * @param props IProps
 * @returns JSX.Elements
 */
export function ImageMediasGrid(props: Readonly<IProps>): JSX.Element {
  const { parentObject, className, legacyHeader, title } = props;
  const { t } = useTranslation();
  const [data, setData] = React.useState<FileModel[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchFilter, setSearchFilter] = React.useState<string>('');
  const baseObjectPanelContext = useContext(BaseObjectPanelContext);
  const [
    isExternalFilesystemInaccessible,
    setIsExternalFilesystemInaccessible,
  ] = React.useState<boolean>(false);
  const [, , category] = useActiveCategories();

  const [mediaCategories, setMediaCategories] = React.useState<MediaCategory[]>(
    [],
  );
  const [filteredCategories, setFilteredCategories] = React.useState<
    MediaCategory[]
  >([]);

  React.useEffect(() => {
    if (!category) return;
    MediaCategory.getMediaCategories(category?.id).then(setMediaCategories);
  }, [category]);

  const update = (): void => {
    setLoading(true);
    parentObject
      .listFiles(
        searchFilter || undefined,
        undefined,
        'IMAGE',
        filteredCategories,
      )
      .then((res) => {
        setData(res.data.results?.map((file) => new FileModel(file)));
        setLoading(false);
      });
  };

  /**
   * Refetch tree on filter
   */
  React.useEffect(() => {
    update();
  }, [parentObject, searchFilter, filteredCategories.length]);

  React.useEffect(() => {
    const firstFile = data?.[0];
    if (!firstFile?.externalFilesystemId || !firstFile.filepath) return;
    const existsOrPromise = checkPathExists(firstFile.filepath);
    if (existsOrPromise instanceof Promise) {
      existsOrPromise
        .then((exists) => setIsExternalFilesystemInaccessible(!exists))
        .catch((error: Error) => {
          if (
            'message' in error &&
            error.message.includes('No handler registered')
          ) {
            // Electron app not up to date, cannot check for accessibility
            return;
          }
          setIsExternalFilesystemInaccessible(true);
        });
    } else {
      setIsExternalFilesystemInaccessible(!existsOrPromise);
    }
  }, [data]);

  const toggleFavorite = (file: FileModel): void => {
    file.isFavorite = !file.isFavorite;
    MediasManager.partialUpdateFile(file.id, file).then(() => {
      update();
      if (baseObjectPanelContext) baseObjectPanelContext.reloadCarousel();
    });
  };

  const isEmpty = !data?.length;

  return (
    <div className={`${className ?? ''}`}>
      {!legacyHeader ? (
        <div className="flex border-t border-b border-gray-200 dark:border-gray-600 bg-gray-50">
          <TableHeader icon={{ name: 'file-image' }} label={title} />
        </div>
      ) : (
        <PageHeader actions={[]} title={title} />
      )}
      {isExternalFilesystemInaccessible ? (
        <DangerAlert>
          <div className="flex flex-col gap-2 items-center">
            <p>{t('medias.external_filesystem_inaccessible')}</p>
            <IconButton
              icon={{ name: 'arrow-rotate-left' }}
              onClick={(): void => {
                setData([]);
                setIsExternalFilesystemInaccessible(false);
                update();
              }}
            >
              {t('medias.retry')}
            </IconButton>
          </div>
        </DangerAlert>
      ) : (
        <Loader isDataLoaded={!loading}>
          {!!(!isEmpty || searchFilter || filteredCategories.length) && (
            <div className="flex flex-row">
              <SearchBar
                onClear={(): void => {
                  setSearchFilter('');
                }}
                onSearch={(value): void => setSearchFilter(value)}
                value={searchFilter}
                className={{
                  container:
                    '!border-gray-200 rounded-none border-0 border-b-[1px] !w-full',
                }}
              />
              <DropDown
                menuItems={
                  mediaCategories.length
                    ? mediaCategories.map((category) => (
                        <CheckboxInput
                          key={category.id}
                          label={category.label}
                          name={category.id.toString()}
                          className={{ container: 'px-2 py-1' }}
                          value={filteredCategories.includes(category)}
                          onChange={(): void => {
                            if (filteredCategories.includes(category)) {
                              setFilteredCategories(
                                filteredCategories.filter(
                                  (c) => c.id !== category.id,
                                ),
                              );
                            } else {
                              setFilteredCategories([
                                ...filteredCategories,
                                category,
                              ]);
                            }
                          }}
                        />
                      ))
                    : [
                        <div
                          key={'categoryNoOptionMessage'}
                          className="py-1 px-2 text-sm text-gray-800 dark:text-gray-200"
                        >
                          {t('medias.categoryNoOptionMessage')}
                        </div>,
                      ]
                }
              >
                <IconButton
                  title={t('medias.filterButtonLabel')}
                  className="w-12 h-11 rounded-none border-t-0 border-r-0 flex items-center justify-center"
                  iconClassName="text-xl mr-[2px]"
                  icon={{ name: 'filters', familyStyle: 'fal' }}
                />
              </DropDown>
            </div>
          )}
          <div className="absolute inset-0 top-11 z-20">
            {!isEmpty ? (
              // if there are images
              <div className="h-full flex flex-col items-center gap-1">
                <ul className="p-2 h-fit grid grid-cols-3 gap-x-2 gap-y-4 sm:grid-cols-2 sm:gap-x-3 overflow-y-scroll no-scrollbar">
                  {data.map((file) => (
                    <li className="relative p-1" key={file.id}>
                      <div
                        title={file.label}
                        className="group block w-full aspect-w-10 aspect-h-7 rounded-sm bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
                      >
                        <ImageThumbnail
                          image={file}
                          isClickable
                          width={200}
                          height={200}
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />

                        <Icon
                          icon={{
                            name: 'star',
                            familyStyle: file.isFavorite ? 'fas' : 'fal',
                          }}
                          color="#FFCA28"
                          className="absolute right-2 top-2"
                          onClick={(): void => toggleFavorite(file)}
                        />
                      </div>
                      <p className="text-center mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                        {file.label}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {isEmpty && !loading ? (
              // else placeholder
              <NoContentMessage
                icon={{ name: 'file' }}
                label={t('medias.noContent')}
                className="top-11"
              />
            ) : null}
          </div>
        </Loader>
      )}
    </div>
  );
}

ImageMediasGrid.defaultProps = {
  title: 'Images',
  legacyHeader: false,
};
