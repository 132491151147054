import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  ViewElementType,
  ViewElementTypeViewStyle,
} from '@smack/core/api/models/views/ViewElement/ViewElement';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';
import { AttributeViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeViewElementRenderer';
import { DisplayedButtonViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/DisplayedButtonViewElementRenderer/DisplayedButtonViewElementRenderer';
import { LinkFieldViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/LinkFieldViewElementRenderer/LinkFieldViewElementRenderer';
import { ObjectLinkGroupsViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/ObjectLinkGroupsViewElementRenderer/ObjectLinkGroupsViewElementRenderer';
import { ObjectProjectsViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/ObjectProjectsViewElementRenderer/ObjectProjectsViewElementRenderer';
import { SupersetDashboardViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/SupersetDashboardViewElementRenderer/SupersetDashboardViewElementRenderer';
import { LinkGroupsTableRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/ViewRenderer/LinkGroupsTableRenderer';
import type { ViewElementRendererProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import { BaseObjectPanelMediasRouter } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/Medias';
import { DetailsSheet } from '@smack/core/views/oldViewsToSort/Views/Objects/DetailsSheet/DetailsSheet';
import { type FC, useMemo } from 'react';

export const ViewElementRenderer: FC<ViewElementRendererProps> = ({
  viewElement,
  props,
}) => {
  const properties = useMemo(
    () => ({
      ...props,
      parameters: viewElement.fieldTypeParameters,
    }),
    [viewElement, props],
  );

  const renderDefaultValues = () => {
    if (viewElement.fieldType === DisplayedFieldType.READ_BADGE) {
      const value = viewElement.values?.[0];
      if (value) {
        return (
          <Badge icon={value.icon} color={value.color}>
            {value.value}
          </Badge>
        );
      }
    }
    return viewElement.getValueIfExist();
  };

  const rendererMap = {
    [ViewElementType.ATTRIBUTE]: (
      <AttributeViewElementRenderer
        viewElement={viewElement}
        props={properties}
      />
    ),
    [ViewElementType.DISPLAYED_BUTTON]: (
      <DisplayedButtonViewElementRenderer
        viewElement={viewElement}
        props={properties}
      />
    ),
    [ViewElementType.LINK_FIELD]: (
      <LinkFieldViewElementRenderer
        viewElement={viewElement}
        props={properties}
      />
    ),
    [ViewElementType.OBJECT_LINK_GROUPS]: (
      <ObjectLinkGroupsViewElementRenderer
        viewElement={viewElement}
        props={properties}
      />
    ),
    [ViewElementType.OBJECT_PROJECTS]: (
      <ObjectProjectsViewElementRenderer
        viewElement={viewElement}
        props={properties}
      />
    ),
    [ViewElementType.SUPERSET_DASHBOARD]: (
      <SupersetDashboardViewElementRenderer
        viewElement={viewElement}
        props={properties}
      />
    ),
    [ViewElementType.MEDIAS]: (
      <div className="relative flex flex-col m-3 min-h-[calc(100vh-16rem)]">
        <BaseObjectPanelMediasRouter
          data-testid="nonRoutedMediasContent"
          baseObject={props?.baseObject as BaseObject}
        />
      </div>
    ),
    [ViewElementType.VIEW]: (
      <>
        {viewElement?.view?.style === ViewElementTypeViewStyle.TABLE && (
          <div
            className="flex-grow"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          >
            <LinkGroupsTableRenderer
              viewElement={viewElement}
              props={properties}
            />
          </div>
        )}
        {viewElement?.view?.style === ViewElementTypeViewStyle.PAGE && (
          <div
            className="flex flex-grow overflow-y-auto"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          >
            <DetailsSheet
              baseObjectId={props?.baseObject?.id}
              scheduleId={props?.baseObject?.scheduleId}
            />
          </div>
        )}
      </>
    ),
  };

  return rendererMap[viewElement.type] || renderDefaultValues();
};
