import { LogAction } from '@smack/core/api/models/logs/Log';
import type { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import { TaskLogCreatedChecklistFeed } from '@smack/core/views/Feeds/LogsFeed/TaskLogFeed/TaskLogCreatedChecklistFeed';
import { TaskLogDeletedChecklistFeed } from '@smack/core/views/Feeds/LogsFeed/TaskLogFeed/TaskLogDeletedChecklistFeed';
import type React from 'react';
import { TaskLogCreationFeed } from './TaskLogCreationFeed';
import { TaskLogDateFeed } from './TaskLogDate';
import { TaskLogMessageFeed } from './TaskLogMessageFeed';
import { TaskLogModificationFeed } from './TaskLogModificationFeed';
import { TaskLogStatusFeed } from './TaskLogStatusFeed';

interface IProps {
  log: TaskLog;
  first: boolean;
  last: boolean;
}

export const TaskLogFeed: React.FC<IProps> = ({ log, first, last }) => {
  if (log.action === LogAction.CREATE) {
    return (
      <TaskLogCreationFeed key={log.id} log={log} first={first} last={last} />
    );
  }
  if (log.action === LogAction.PROCESS && log.content?.taskMessage) {
    return (
      <TaskLogMessageFeed key={log.id} log={log} first={first} last={last} />
    );
  }
  if (log.content?.createdChecklistLabel) {
    return <TaskLogCreatedChecklistFeed log={log} first={first} last={last} />;
  }
  if (log.content?.deletedChecklistLabel) {
    return <TaskLogDeletedChecklistFeed log={log} first={first} last={last} />;
  }
  switch (log.content?.taskField) {
    case 'status':
      //Return the log feed if the log is for status
      return (
        <TaskLogStatusFeed key={log.id} log={log} first={first} last={last} />
      );
    case 'start_at':
    case 'end_at':
    case 'deadline_at':
      //Return the log feed if the log is for start_at/end_at/deadline_at
      return (
        <TaskLogDateFeed key={log.id} log={log} first={first} last={last} />
      );
  }
  return (
    <TaskLogModificationFeed key={log.id} log={log} first={first} last={last} />
  );
};
