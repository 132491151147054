import type { ChecklistLog } from '@smack/core/api/models/tasks/ChecklistLog';
import { ChecklistLogCreatedItemFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistLogFeed/ChecklistLogCreatedItemFeed';
import { ChecklistLogDeletedItemFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistLogFeed/ChecklistLogDeletedItemFeed';
import { ChecklistLogModificationFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistLogFeed/ChecklistLogModificationFeed';
import { ChecklistLogRenamedFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistLogFeed/ChecklistLogRenamedFeed';
import type React from 'react';

interface IProps {
  log: ChecklistLog;
  first: boolean;
  last: boolean;
}

export const ChecklistLogFeed: React.FC<IProps> = ({ log, first, last }) => {
  if (log.content?.createdChecklistItemLabel) {
    return <ChecklistLogCreatedItemFeed log={log} first={first} last={last} />;
  }
  if (log.content?.deletedChecklistItemLabel) {
    return <ChecklistLogDeletedItemFeed log={log} first={first} last={last} />;
  }
  if (log.content?.checklistField === 'label') {
    return <ChecklistLogRenamedFeed log={log} first={first} last={last} />;
  }

  return <ChecklistLogModificationFeed log={log} first={first} last={last} />;
};
