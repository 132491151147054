import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Group } from '@smack/core/api/models/tasks/Group';
import { Project } from '@smack/core/api/models/tasks/Project/Project';
import { Status } from '@smack/core/api/models/tasks/Status';
import { TaskRule } from '@smack/core/api/models/tasks/TaskRule';
import {
  CreateButton,
  IconButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { DeleteConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { DropDown } from '@smack/core/components/DataDisplay/DropDowns/DropDown';
import { DataDisplayHeader } from '@smack/core/components/DataDisplay/Headers/DataDisplayHeader';
import { IconLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import {
  ShowIfAllowedContext,
  defaultShowIfAllowedContext,
} from '@smack/core/components/DataDisplay/ShowIfAllowed/Context';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed/ShowIfAllowed';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { toggleRefetchProjectStatuses } from '@smack/core/store/projects/actions';
import { useIsMobile } from '@smack/core/utils';
import { GanttProject } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GanttProject/GanttProject';
import { GroupCreateEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/GroupCreateEditModal/GroupCreateEditModal';
import { ProjectEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ProjectEditModal';
import { Rules } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Rules';
import { TaskGroup } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskGroup';
import {
  type ITasksContext,
  TasksContext,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Context';
import { getHeaderLabels } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/utils';
import React from 'react';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface TasksProps {
  taskId: number;
  baseObjectId: number;
}

export const Tasks: React.FC<TasksProps> = ({ taskId, baseObjectId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, , setLastViewAsCurrent] = useActiveView();
  const isMobile = useIsMobile();
  const [baseObject, setBaseObject] = React.useState<BaseObject>();
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);

  const sectionOptions: Option<string>[] = [
    {
      label: t('tasks.tasks'),
      value: 'task',
      icon: { name: 'list-check', familyStyle: 'far' },
    },
    {
      label: t('tasks.rules'),
      value: 'rules',
      icon: { name: 'bolt-auto', familyStyle: 'far' },
      className: 'mx-2',
    },
  ];
  if (!isMobile) {
    sectionOptions.push({
      label: t('tasks.ganttDiagram'),
      value: 'gantt',
      icon: { name: 'chart-gantt' },
    });
  }
  const [activeSectionOption, setActiveSectionOption] = React.useState<Option>(
    sectionOptions[0],
  );

  const [tasksContext, setTasksContext] = React.useState<ITasksContext>();

  const loadProject = React.useCallback(async (): Promise<void> => {
    if (!taskId) {
      setTasksContext(undefined);
      return;
    }
    const [project, taskRules, statuses] = await Promise.allSettled([
      Project.getProject(taskId),
      TaskRule.getRulesFromProjectId(taskId),
      Status.getStatusByProjectId(taskId),
    ]);
    if (project.status === 'rejected') return;
    setTasksContext((previousContext) => {
      return {
        project: project.value,
        statuses:
          statuses.status === 'fulfilled'
            ? statuses.value
            : previousContext?.statuses || [],
        taskRules:
          taskRules.status === 'fulfilled'
            ? taskRules.value
            : previousContext?.taskRules || [],
      };
    });
  }, [taskId]);

  React.useEffect(() => {
    if (baseObjectId) {
      BaseObject.getBaseObject(baseObjectId).then(setBaseObject);
    } else {
      setLastViewAsCurrent();
    }
  }, [baseObjectId]);

  React.useEffect(() => {
    loadProject();
  }, [taskId]);

  const groups = React.useMemo(() => {
    if (!tasksContext?.project?.groups) return [];
    return tasksContext.project.groups.map((group) => new Group(group));
  }, [tasksContext]);

  const [isEditing, setIsEditing] = React.useState(false);

  const switchIsEditing = React.useCallback<React.MouseEventHandler>((e) => {
    e.stopPropagation();
    setIsEditing((isEditingState) => !isEditingState);
  }, []);

  const [isCreatingGroup, setIsCreatingGroup] = React.useState(false);

  const switchIsCreatingGroup = React.useCallback<React.MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      setIsCreatingGroup((isCreatingGroupState) => !isCreatingGroupState);
    },
    [],
  );

  const onSubscribeToggle = React.useCallback<React.MouseEventHandler>(() => {
    if (tasksContext?.project) {
      tasksContext.project.subscribeManager
        .toggleIsSubscribed()
        .then(loadProject);
    }
  }, [tasksContext]);

  const onConfirmDelete = () => {
    setLastViewAsCurrent();
    dispatch(toggleRefetchProjectStatuses(true));
    toast.success(t('tasks.deleteProjectSuccess'));
  };

  if (!tasksContext || !baseObject) return null;

  const { project, taskRules } = tasksContext;

  const buttons = [
    ...sectionOptions.map((option) => (
      <IconButton
        icon={option.icon}
        key={option.value}
        iconClassName={'my-0.5'}
        className={option.className ?? ''}
        onClick={() => setActiveSectionOption(option as Option)}
        title={option.label as string}
      />
    )),
    <IconButton
      icon={{ name: project?.isSubscribed ? 'eye-slash' : 'eye' }}
      key="subscribe"
      className="mx-2"
      iconClassName={'my-0.5'}
      onClick={onSubscribeToggle}
      title={t(project?.isSubscribed ? 'tasks.unsubscribe' : 'tasks.subscribe')}
    />,
    <ShowIfAllowed key="edit">
      <IconButton
        icon={{ name: 'pen' }}
        onClick={switchIsEditing}
        iconClassName={'my-0.5'}
        title={t('edit.edit')}
      />
    </ShowIfAllowed>,
    <ShowIfAllowed key="delete" permissions={['isManageable']}>
      <DropDown
        placement="bottom-end"
        menuItems={[
          <button
            key="delete-procedure"
            type="button"
            className="text-sm px-4 py-2 text-gray-500 rounded hover:bg-gray-50"
            onClick={() => setOpenDeleteAlert(true)}
          >
            {t('delete.procedure')}
          </button>,
        ]}
      >
        <IconButton
          icon={{ name: 'ellipsis-vertical' }}
          iconClassName={'my-0.5'}
          className={'mx-2'}
          title={t('delete.procedure')}
        />
      </DropDown>
    </ShowIfAllowed>,
    <IconButton
      key="close-project"
      icon={{ name: 'times' }}
      onClick={setLastViewAsCurrent}
      iconClassName={'my-0.5'}
      title={t('close')}
    />,
  ];

  return (
    <ShowIfAllowedContext.Provider
      value={
        tasksContext.project.tplProject?.category || defaultShowIfAllowedContext
      }
    >
      <ProjectEditModal
        isOpen={isEditing}
        setIsOpen={setIsEditing}
        project={project}
        reloadProject={loadProject}
      />
      <GroupCreateEditModal
        isOpen={isCreatingGroup}
        setIsOpen={setIsCreatingGroup}
        project={project}
        reloadProject={loadProject}
      />
      {openDeleteAlert && (
        <DeleteConfirmAlert
          onCloseModal={() => setOpenDeleteAlert(false)}
          onCloseButton={() => setOpenDeleteAlert(false)}
          text={<Trans t={t} i18nKey="tasks.deleteProjectModalContent" />}
          title={t('tasks.deleteProjectModalTitle')}
          onOk={(): void => {
            project
              .delete()
              .then(onConfirmDelete)
              .catch(() => toast.error(t('tasks.deleteProjectError')));
          }}
        />
      )}

      <div
        style={{ maxHeight: 'calc(100vh - 60px)' }}
        className="w-full bg-view overflow-y-scroll z-10"
      >
        <DataDisplayHeader
          color={baseObject?.category?.color ?? 'black'}
          icon={baseObject?.category?.icon}
          title={
            <div className="flex flex-col">
              {project.label}
              {getHeaderLabels(baseObject, project)?.map((label) => {
                return (
                  <IconLabel
                    className="text-sm opacity-80"
                    key={label.label}
                    icon={label.icon}
                    label={label.label}
                  />
                );
              })}
            </div>
          }
          actions={buttons}
        />
        <TasksContext.Provider value={tasksContext}>
          <div className="w-full p-4 relative">
            {activeSectionOption.value === 'task' ? (
              <>
                {groups?.map((group) => (
                  <TaskGroup
                    key={group.id}
                    baseObject={baseObject}
                    group={group}
                    project={project}
                    reloadProject={loadProject}
                  />
                ))}
                <ShowIfAllowed>
                  <div className="py-2">
                    <CreateButton onClick={switchIsCreatingGroup}>
                      {t('create.group')}
                    </CreateButton>
                  </div>
                </ShowIfAllowed>
              </>
            ) : null}
            {activeSectionOption.value === 'rules' && (
              <Rules rules={taskRules} />
            )}
            {activeSectionOption.value === 'gantt' && (
              <GanttProject project={project} reloadProject={loadProject} />
            )}
          </div>
        </TasksContext.Provider>
      </div>
    </ShowIfAllowedContext.Provider>
  );
};
