import { lazy } from 'react';

import type { IconName } from '@fortawesome/fontawesome-common-types';
import type { IAttributeValue } from '@smack/core/api/models/categories/Attribute/Attribute';
import {
  type FileApiOutput,
  File as FileModel,
} from '@smack/core/api/models/medias/File';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import type { CustomCSSProperties } from '@smack/core/components/Actions/Buttons/Button/Components/Button';
import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';
import { BadgesOverflowWrapper } from '@smack/core/components/DataDisplay/Badges/BadgesOverflowWrapper';
import {
  FileList,
  fileAsFileItem,
} from '@smack/core/components/DataDisplay/FileList';
import { ImageThumbnail } from '@smack/core/components/DataDisplay/ImageThumbnail/ImageThumbnail';
import { ModalLongText } from '@smack/core/components/DataDisplay/Modals/ModalLongText';
import { Url } from '@smack/core/components/DataDisplay/Url/Url';
import type { ILocationInputValue } from '@smack/core/components/DataInput/LocationInput/LocationInput';
import type { AppState } from '@smack/core/store';
import { generateDuotone } from '@smack/core/utils/ColorUtils';
import { determinePhoneRegion } from '@smack/core/utils/PhoneUtils';
import { StringUtils } from '@smack/core/utils/String';
import { uuid } from '@tinymce/tinymce-react/lib/es2015/main/ts/Utils';
import parsePhoneNumberFromString from 'libphonenumber-js';
import type React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const MiniMap = lazy(() =>
  import('@smack/core/components/DataDisplay/MiniMap').then((module) => ({
    default: module.MiniMap,
  })),
);

export enum AttributeType {
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  DATETIME = 'DATETIME',
  DATE = 'DATE',
  LOCATION = 'LOCATION',
  RECURRENCE = 'RECURRENCE',
  BOOL = 'BOOL',
  FLOAT = 'FLOAT',
  MEDIA = 'MEDIA',
  THUMBNAIL = 'THUMBNAIL',
  VALUELISTITEM = 'VALUELIST_ITEM',
  URL = 'URL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  LINK_GROUP = 'LINK_GROUP',
}

export const IconByAttributeType: Record<AttributeType, IconName> = {
  [AttributeType.TEXT]: 'text',
  [AttributeType.INTEGER]: 'input-numeric',
  [AttributeType.DATETIME]: 'calendar-clock',
  [AttributeType.DATE]: 'calendar',
  [AttributeType.BOOL]: 'value-absolute',
  [AttributeType.FLOAT]: 'input-numeric',
  [AttributeType.VALUELISTITEM]: 'list-radio',
  [AttributeType.RECURRENCE]: 'refresh',
  [AttributeType.LOCATION]: 'location-dot',
  [AttributeType.MEDIA]: 'file',
  [AttributeType.THUMBNAIL]: 'image',
  [AttributeType.EMAIL]: 'at',
  [AttributeType.PHONE]: 'phone',
  [AttributeType.URL]: 'up-right-from-square',
  [AttributeType.LINK_GROUP]: 'link',
};

const LinkGroupRenderValue = ({ value }: { value: IAttributeValue }) => {
  const { settings } = useSelector((state: AppState) => state.App);
  const color = settings?.color ?? settings?.defaultColor;
  const object = value.targetBaseobject;
  const [primary, secondary] = generateDuotone(color, 0.5);

  if (!object) return null;

  return (
    <p
      className="text-sm text-[var(--button-bg-color)] hover:text-[var(--button-hover-color)]"
      style={
        {
          '--button-bg-color': primary,
          '--button-hover-color': secondary,
        } as CustomCSSProperties
      }
    >
      <Link
        onClick={(e) => e.stopPropagation()}
        to={object.frontEndpoint ?? ''}
      >
        {object?.title}
      </Link>
    </p>
  );
};

const LinkGroupRenderMultipleValues = ({
  values,
  viewUsage,
}: {
  values: IAttributeValue[];
  viewUsage?: ViewUsage;
}) => {
  const { settings } = useSelector((state: AppState) => state.App);
  const color = settings?.color ?? settings?.defaultColor;
  const [primary, secondary] = generateDuotone(color, 0.5);

  return defaultRenderMultipleWrapper(
    values.map((v) => {
      const object = v.targetBaseobject;
      if (!object) return null;
      return (
        <p
          key={v.id}
          className="text-sm text-[var(--button-bg-color)] hover:text-[var(--button-hover-color)]"
          style={
            {
              '--button-bg-color': primary,
              '--button-hover-color': secondary,
            } as CustomCSSProperties
          }
        >
          <a href={StringUtils.sanitizeHRefLink(object.frontEndpoint)}>
            {object?.title}
          </a>
        </p>
      );
    }),
    viewUsage,
  );
};

export interface IAttributeValueFromType {
  type: AttributeType;
  renderValue: (
    value: IAttributeValue,
    viewUsage?: ViewUsage,
  ) => React.ReactNode;
  renderMultipleValues: (
    values: IAttributeValue[],
    viewUsage?: ViewUsage,
  ) => React.ReactNode;
}

export const defaultRenderValue: IAttributeValueFromType['renderValue'] = (
  value,
) => {
  return (
    <p className="text-sm text-text ">
      {value.formattedValue ?? (value.value || '-')}
    </p>
  );
};

export const defaultRenderMultipleWrapper = (
  children: React.ReactNode,
  viewUsage?: ViewUsage,
): React.ReactNode => {
  return (
    <BadgesOverflowWrapper
      className={viewUsage === ViewUsage.DETAILS ? 'justify-end flex-wrap' : ''}
      minBadgeCount={viewUsage === ViewUsage.DETAILS ? 10 : 1}
    >
      {children}
    </BadgesOverflowWrapper>
  );
};

const defaultRenderMultipleValues: IAttributeValueFromType['renderMultipleValues'] =
  (values, viewUsage) => {
    return defaultRenderMultipleWrapper(
      values.map((v): React.ReactNode => {
        return <span key={uuid('')}>{defaultRenderValue(v)}</span>;
      }),
      viewUsage,
    );
  };

const mediaRenderValue: IAttributeValueFromType['renderValue'] = (
  value,
  viewUsage,
): React.ReactNode => {
  const file = new FileModel(value?.value as unknown as FileApiOutput);
  if (viewUsage === ViewUsage.TABLE || !value.value)
    return (
      <button
        type="button"
        title={file.label}
        onClick={(event) => {
          file.open();
          event.stopPropagation();
        }}
        className="text-sm text-text text-start truncate max-w-[36ch] text-blue-500"
      >
        {file.label}
      </button>
    );
  return (
    <div className="w-full max-w-lg min-w-0">
      {file.mimetype?.isImage ? (
        <div
          className={`w-fit relative rounded overflow-hidden ${viewUsage === ViewUsage.DETAILS ? 'ml-auto' : ''}`}
        >
          <ImageThumbnail image={file} width={200} height={200} isClickable />
        </div>
      ) : (
        <FileList files={[fileAsFileItem(file)]} />
      )}
    </div>
  );
};

const mediaRenderMultipleValues: IAttributeValueFromType['renderMultipleValues'] =
  (values, viewUsage): React.ReactNode => {
    const files = values.map(
      (value) => new FileModel(value.value as unknown as FileApiOutput),
    );
    if (viewUsage === ViewUsage.TABLE) {
      return defaultRenderMultipleWrapper(
        files.map((file) => {
          return (
            <button
              type="button"
              key={file.id}
              title={file.label}
              onClick={(event) => {
                file.open();
                event.stopPropagation();
              }}
              className="text-sm text-text text-start truncate max-w-[36ch] text-blue-500"
            >
              {file.label}
            </button>
          );
        }),
        viewUsage,
      );
    }

    return (
      <div className="w-full max-w-lg">
        <FileList files={files.map(fileAsFileItem)} />
      </div>
    );
  };

export const attributeValueFromType: IAttributeValueFromType[] = [
  {
    type: AttributeType.TEXT,
    renderValue: (value: IAttributeValue, viewUsage): React.ReactNode => {
      if (viewUsage === ViewUsage.SHEET)
        return defaultRenderValue(value, viewUsage);

      if (viewUsage === ViewUsage.LIST_BLOCK) return value.value;

      const maxLength = viewUsage === ViewUsage.TABLE ? 100 : 250;

      return (
        <ModalLongText
          text={value.formattedValue ?? (value.value || '-')}
          maxLength={maxLength}
          renderLongText={(text): React.ReactNode => (
            <p className="text-sm text-text prose">{text}</p>
          )}
          renderShortText={(text): React.ReactNode => (
            <p className="text-sm text-text ">
              {text.length > maxLength
                ? `${text.slice(0, maxLength)}...`
                : text}
            </p>
          )}
        />
      );
    },
    renderMultipleValues: defaultRenderMultipleValues,
  },
  {
    type: AttributeType.INTEGER,
    renderValue: defaultRenderValue,
    renderMultipleValues: defaultRenderMultipleValues,
  },
  {
    type: AttributeType.DATETIME,
    renderValue: defaultRenderValue,
    renderMultipleValues: defaultRenderMultipleValues,
  },
  {
    type: AttributeType.DATE,
    renderValue: defaultRenderValue,
    renderMultipleValues: defaultRenderMultipleValues,
  },
  {
    type: AttributeType.LOCATION,
    renderValue: (value, viewUsage): React.ReactNode => {
      if (viewUsage === ViewUsage.TABLE) return ' - ';
      const locationValue = value.value as ILocationInputValue;
      return (
        <MiniMap
          className={'max-w-[300px]'}
          location={locationValue?.location}
          geometry={locationValue?.geometry}
        />
      );
    },
    renderMultipleValues: (): React.ReactNode => ' - ',
  },
  {
    type: AttributeType.RECURRENCE,
    renderValue: (): React.ReactNode => ' - ',
    renderMultipleValues: (): React.ReactNode => ' - ',
  },
  {
    type: AttributeType.BOOL,
    renderValue: (value: IAttributeValue): React.ReactNode => {
      return (
        <Badge
          className="uppercase"
          color={value.value ? '#22c55e' : '#ef4444'}
        >
          {value.value ? 'Oui' : 'Non'}
        </Badge>
      );
    },
    renderMultipleValues: (
      values: IAttributeValue[],
      viewUsage?: ViewUsage,
    ): React.ReactNode => {
      return defaultRenderMultipleWrapper(
        values.map((v) => {
          return (
            <Badge
              key={v.value}
              className="uppercase"
              color={v.value ? '#22c55e' : '#ef4444'}
            >
              {v.value ? 'Oui' : 'Non'}
            </Badge>
          );
        }),
        viewUsage,
      );
    },
  },
  {
    type: AttributeType.FLOAT,
    renderValue: defaultRenderValue,
    renderMultipleValues: defaultRenderMultipleValues,
  },
  {
    type: AttributeType.MEDIA,
    renderValue: mediaRenderValue,
    renderMultipleValues: mediaRenderMultipleValues,
  },
  {
    type: AttributeType.THUMBNAIL,
    renderValue: mediaRenderValue,
    renderMultipleValues: mediaRenderMultipleValues,
  },
  {
    type: AttributeType.VALUELISTITEM,
    renderValue: (v: IAttributeValue): React.ReactNode => {
      return (
        <Badge className="uppercase" color={v.color ?? '#ef4444'}>
          {v.value}
        </Badge>
      );
    },
    renderMultipleValues: (
      values: IAttributeValue[],
      viewUsage?: ViewUsage,
    ): React.ReactNode => {
      return defaultRenderMultipleWrapper(
        values.map((v) => {
          return (
            <Badge
              key={v.value}
              className="uppercase"
              color={v.color ?? '#ef4444'}
            >
              {v.value}
            </Badge>
          );
        }),
        viewUsage,
      );
    },
  },
  {
    type: AttributeType.URL,
    renderValue: (value: IAttributeValue): React.ReactNode => {
      return <Url src={value.formattedValue ?? value.value} />;
    },
    renderMultipleValues: (
      values: IAttributeValue[],
      viewUsage?: ViewUsage,
    ): React.ReactNode => {
      return defaultRenderMultipleWrapper(
        values.map((v) => {
          return (
            <p
              key={v.value}
              className="text-sm text-text truncate max-w-[36ch]"
            >
              <a
                href={StringUtils.sanitizeHRefLink(v.formattedValue ?? v.value)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {v.formattedValue ?? v.value}
              </a>
            </p>
          );
        }),
        viewUsage,
      );
    },
  },
  {
    type: AttributeType.EMAIL,
    renderValue: (value: IAttributeValue): React.ReactNode => {
      return (
        <p className="text-sm text-text break-all">
          <a
            href={StringUtils.sanitizeHRefLink(
              `mailto:${value.formattedValue ?? value.value ?? ''}`,
            )}
          >
            {value.formattedValue ?? value.value}
          </a>
        </p>
      );
    },
    renderMultipleValues: (
      values: IAttributeValue[],
      viewUsage?: ViewUsage,
    ): React.ReactNode => {
      return defaultRenderMultipleWrapper(
        values.map((v) => {
          return (
            <p key={v.value} className="text-sm text-text break-all">
              <a
                href={StringUtils.sanitizeHRefLink(
                  `mailto:${v.formattedValue ?? v.value ?? ''}`,
                )}
              >
                {v.formattedValue ?? v.value}
              </a>
            </p>
          );
        }),
        viewUsage,
      );
    },
  },
  {
    type: AttributeType.PHONE,
    renderValue: (value: IAttributeValue, viewUsage) => {
      const parsedPhoneNumber = parsePhoneNumberFromString(value.value ?? '');
      if (parsedPhoneNumber) {
        return (
          <p className="text-sm text-text truncate max-w-[36ch] text-blue-500">
            <a
              href={`tel:${parsedPhoneNumber.number}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {determinePhoneRegion() === parsedPhoneNumber.country
                ? parsedPhoneNumber.formatNational()
                : parsedPhoneNumber.formatInternational()}
            </a>
          </p>
        );
      }
      return defaultRenderValue(value, viewUsage);
    },
    renderMultipleValues: defaultRenderMultipleValues,
  },
  {
    type: AttributeType.LINK_GROUP,
    renderValue: (value: IAttributeValue): React.ReactNode => {
      return <LinkGroupRenderValue value={value} />;
    },
    renderMultipleValues: (values, viewUsage): React.ReactNode => {
      return (
        <LinkGroupRenderMultipleValues values={values} viewUsage={viewUsage} />
      );
    },
  },
];
