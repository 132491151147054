import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Category } from '@smack/core/api/models/categories/Category';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  CancelButton,
  CloseButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Loader } from '@smack/core/components/Actions/Loader';
import {
  InfoAlert,
  WarningAlert,
} from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { CategoriesChoicesSelect } from '@smack/core/components/DataInput/CategoriesChoicesSelect/CategoriesChoicesSelect';
import { CustomRecurrenceModal } from '@smack/core/components/DataInput/RecurrenceInput/Components/CustomRecurrenceModal/CustomRecurrenceModal';
import {
  type EndRecurrenceOutput,
  EndType,
  SelectEndRecurrenceInput,
} from '@smack/core/components/DataInput/SelectEndRecurrenceInput';
import { SelectRecurrenceInput } from '@smack/core/components/DataInput/SelectRecurrenceInput';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { RRuleUtils } from '@smack/core/utils/RRule';
import { toast } from 'react-hot-toast';
import { type RRule, RRuleSet, rrulestr } from 'rrule';

export interface ICreateTimeSeriesBaseObjectProps {
  open: boolean;
  baseObject?: BaseObject;
  setOpen: (open: boolean) => void;
  onValidate?: () => void;
}

const initialRule = 'RRULE:FREQ=WEEKLY';
const initialEnd: EndRecurrenceOutput = {
  type: EndType.NextYear,
};

export const CreateTimeSeriesBaseObjectModal: React.FC<
  ICreateTimeSeriesBaseObjectProps
> = ({ open, setOpen, baseObject, onValidate }) => {
  const [t] = useTranslation();
  const [loaded, setLoaded] = React.useState(true);
  const [hasBeenCreated, setHasBeenCreated] = React.useState(false);
  const [recurrence, setRecurrence] = React.useState<string>(initialRule);
  const [openCustomModal, setOpenCustomModal] = React.useState(false);
  const [categoryValue, setCategoryValue] = React.useState<Category | null>(
    null,
  );
  const [endValue, setEndValue] =
    React.useState<EndRecurrenceOutput>(initialEnd);
  const copydateRange = React.useMemo(() => {
    if (baseObject?.startAt && baseObject?.endAt) {
      return DateUtils.getDateRangeLocalString(
        new Date(baseObject.startAt),
        new Date(baseObject.endAt),
        false,
      );
    }
  }, [baseObject]);

  const handleSave = (): void => {
    setLoaded(false);

    const rrule = RRuleUtils.getRRuleSetFromString(recurrence);
    const date = baseObject?.startAt
      ? new Date(baseObject?.startAt)
      : undefined;
    if (!rrule || !date) return;

    if (endValue.type === EndType.NextYear) {
      const newDate = new Date(date);
      newDate.setFullYear(date.getFullYear() + 1);
      rrule.origOptions.until = newDate;
    } else if (endValue.type === EndType.OnDate) {
      if (!endValue.value) {
        setLoaded(true);
        toast.error(t('createTimeSeriesModal.creationError'));
        return;
      }
      rrule.origOptions.until = new Date(endValue.value);
    } else if (endValue.type === EndType.NumberOfExecution) {
      if (!endValue.value) {
        setLoaded(true);
        toast.error(t('createTimeSeriesModal.creationError'));
        return;
      }
      rrule.origOptions.count = endValue.value as number;
    }

    baseObject
      ?.createTimeSeries(
        rrule,
        baseObject?.startAt ? new Date(baseObject?.startAt) : undefined,
        baseObject?.endAt ? new Date(baseObject?.endAt) : undefined,
        categoryValue?.id,
      )
      .then(() => {
        onValidate?.();
        setHasBeenCreated(true);
      })
      .catch(() => {
        toast.error(t('createTimeSeriesModal.creationError'));
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  const handleRecurrenceChange = (val: string): void => {
    if (val === 'CUSTOM') {
      setOpenCustomModal(true);
    }
    setRecurrence(val);
  };

  const modalContent = (): React.ReactNode => {
    if (!baseObject?.startAt || !baseObject?.endAt) {
      return (
        <WarningAlert>
          {t('createTimeSeriesModal.objectNoSchedule')}
        </WarningAlert>
      );
    }
    if (baseObject?.baseobjectGroupId) {
      return (
        <WarningAlert>{t('createTimeSeriesModal.alreadyCreated')}</WarningAlert>
      );
    }

    if (hasBeenCreated) {
      return (
        <div className="flex flex-col gap-3 min-w-[400px] max-w-[500px]">
          <InfoAlert
            icon={{ name: 'check' }}
            title={t('createTimeSeriesModal.confirmCreation')}
          >
            <p>{t('createTimeSeriesModal.confirmationMessage')}</p>
          </InfoAlert>

          <div className="flex items-center justify-end gap-3">
            <CloseButton onClick={(): void => setOpen(false)} />
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-3 min-w-[400px]">
        <p className="text-sm text-gray-600 mt-2">
          <Trans
            t={t}
            i18nKey={'createTimeSeriesModal.copyRuleLabel'}
            components={{
              bold: <span className={'font-bold'} />,
            }}
            values={{
              object: baseObject.title,
              date: copydateRange,
            }}
          />
        </p>
        <SelectRecurrenceInput
          value={recurrence}
          dtStart={new Date(baseObject.startAt)}
          onChange={handleRecurrenceChange}
          allowEmpty={false}
          allowCustom={true}
        />
        <p className="text-sm text-gray-600 mt-2">
          {t('createTimeSeriesModal.copyRuleEndLabel', {
            object: baseObject.title,
            date: copydateRange,
          })}
        </p>
        {endValue?.type === EndType.Never && (
          <WarningAlert>
            <p>{t('createTimeSeriesModal.warningNoEnd')}</p>
          </WarningAlert>
        )}
        <SelectEndRecurrenceInput value={endValue} onChange={setEndValue} />

        <p className="text-sm text-gray-600 mt-2">
          {t('createTimeSeriesModal.excludeCategory')}
        </p>
        <CategoriesChoicesSelect
          value={categoryValue}
          onChange={setCategoryValue}
          isSearchable={true}
        />
        <div className="flex items-center justify-end mt-2 gap-3">
          <CancelButton onClick={(): void => setOpen(false)} />
          {recurrence ? (
            <SaveButton
              onClick={handleSave}
              data-testid="CreateTimeSeriesSaveButton"
            />
          ) : null}
        </div>
      </div>
    );
  };

  const computedRRuleSetValue = React.useMemo<RRuleSet | undefined>(() => {
    if (!recurrence) return undefined;
    try {
      return rrulestr(recurrence, {
        forceset: true,
      }) as RRuleSet;
    } catch {
      return undefined;
    }
  }, [recurrence]);

  /**
   * handle input change
   *
   * @param {RRule} val
   */
  const handleChange = (val?: RRule): void => {
    if (val) {
      if (baseObject?.startAt) {
        val.origOptions.dtstart = new Date(baseObject.startAt);
      }
      const set = new RRuleSet();
      set.rrule(val);
      computedRRuleSetValue?.exdates()?.forEach((exDate) => {
        set.exdate(exDate);
      });
      let rruleString = set.toString();

      // Extract only the RRULE portion (removes DTSTART and other properties)
      const parsedRRule = /RRULE:.+/m.exec(rruleString);
      if (parsedRRule) {
        rruleString = parsedRRule[0];
      }
      setRecurrence(rruleString);
    }
  };

  return (
    <>
      <Modal
        icon={{ name: 'copy' }}
        title={t('createTimeSeriesModal.title')}
        open={open}
        onClose={setOpen}
        noClickOutside={true}
      >
        <Loader isDataLoaded={loaded}>
          <div className="max-w-[500px]">{modalContent()}</div>
        </Loader>
      </Modal>

      <CustomRecurrenceModal
        open={openCustomModal}
        setOpen={setOpenCustomModal}
        computedRRuleSetValue={computedRRuleSetValue}
        handleChange={handleChange}
        noEndRule={true}
      />
    </>
  );
};
