import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { Task } from '@smack/core/api/models/tasks/Task';
import {
  AddButton,
  IconButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { MarkdownBlock } from '@smack/core/components/DataDisplay/MarkdownBlock';
import { MediasTree } from '@smack/core/components/DataDisplay/Medias/MediasTree/MediasTree';
import { useShowIfAllowedContext } from '@smack/core/components/DataDisplay/ShowIfAllowed/Context';
import { ShowIfAllowed } from '@smack/core/components/DataDisplay/ShowIfAllowed/ShowIfAllowed';
import { AutoLinkGroups } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/AutoLinkGroups/AutoLinkGroups';
import { Actions } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Actions';
import { ChangeStatus } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ChangeStatus';
import { ChecklistBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ChecklistBlock';
import { ChecklistCreateEditModal } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/ChecklistBlock/Components/ChecklistCreateEditModal';
import { Comments } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/Comments';
import { TaskFormViewBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskFormViewBlock/TaskFormViewBlock';
import { TaskRulesBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskRulesBlock';
import { TableBlock } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableBlock';
import { TableHeaderAction } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/TaskTable/Components/TableHeaderAction';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  task: Task;
  baseObject: BaseObject;
  reloadProject: () => Promise<void>;
  switchIsEditing?: React.MouseEventHandler;
}

/**
 * TaskTable Component
 * the table in the task feed
 * @param props IProps
 * @returns JSX.ELEMENT
 */
export const TaskTable = (props: IProps): JSX.Element => {
  const { task, baseObject, reloadProject, switchIsEditing } = props;

  const [isTaskConflictUpdating, setIsTaskConflictUpdating] =
    React.useState(false);

  const [isCreatingChecklist, setIsCreatingChecklist] = React.useState(false);
  const switchIsCreatingChecklist = React.useCallback(() => {
    setIsCreatingChecklist(
      (isCreatingChecklistState) => !isCreatingChecklistState,
    );
  }, []);

  const { isWritable } = useShowIfAllowedContext();

  const updateTaskConflict = React.useCallback(async () => {
    setIsTaskConflictUpdating(true);
    await task.patch({ isConflictCheck: !task.isConflictCheck });
    await reloadProject();
    setIsTaskConflictUpdating(false);
  }, [task]);

  const blocks = React.useMemo<React.ReactNode>(() => {
    const newBlocks: React.ReactNode[] = [];
    if (task.isConflictCheck)
      newBlocks.push(
        <TableBlock
          title="Conflits"
          icon={{ name: 'warning' }}
          onClose={isWritable ? updateTaskConflict : undefined}
          key="conflicts"
        >
          <AutoLinkGroups task baseObject={baseObject} />
        </TableBlock>,
      );
    if (task.taskFormViewId) {
      newBlocks.push(
        <TaskFormViewBlock
          key={'taskForm'}
          task={task}
          baseObject={baseObject}
        />,
      );
    }
    if (task.taskActions?.length)
      newBlocks.push(
        <TableBlock title="Actions" icon={{ name: 'bolt' }} key="actions">
          <Actions task={task} reloadProject={reloadProject} />
        </TableBlock>,
      );
    if (task.checklists.length) {
      newBlocks.push(
        ...task.checklists.map((checklist) => (
          <ChecklistBlock
            key={`checklist-${checklist.id}`}
            checklist={checklist}
            reloadProject={reloadProject}
          />
        )),
      );
    }
    return newBlocks;
  }, [task, baseObject]);

  const [hideMediaTreeIfEmpty, setHideMediaTreeIfEmpty] = React.useState(true);
  const [isMediaTreeEmpty, setIsMediaTreeEmpty] = React.useState(true);

  const revealMediaTree = React.useCallback(
    () => setHideMediaTreeIfEmpty(false),
    [],
  );

  const closeMediaTree = React.useMemo<(() => void) | undefined>(
    () =>
      isMediaTreeEmpty ? (): void => setHideMediaTreeIfEmpty(true) : undefined,
    [isMediaTreeEmpty],
  );

  const { t } = useTranslation();

  return (
    <div className="md:w-full grid grid-cols-1 md:grid-cols-2 gap-4 items-start -ml-14 md:ml-0">
      {task.description ? (
        <div className="md:col-span-2">
          <TableBlock
            title="Description"
            icon={{ name: 'align-left' }}
            actionButton={
              switchIsEditing ? (
                <ShowIfAllowed>
                  <TableHeaderAction
                    icon={{ name: 'pen' }}
                    title={t('edit.edit')}
                    onClick={switchIsEditing}
                  />
                </ShowIfAllowed>
              ) : null
            }
          >
            <div className="px-3 pb-2">
              <MarkdownBlock>{task.description}</MarkdownBlock>
            </div>
          </TableBlock>
        </div>
      ) : null}

      <div className="flex flex-col gap-2 mt-2">
        {blocks}
        <MediasTree
          hideIfEmpty={hideMediaTreeIfEmpty}
          emptySignal={setIsMediaTreeEmpty}
          parentObject={task.mediasManager}
          containerClassName="flex-grow rounded-lg border overflow-clip relative"
          onClose={closeMediaTree}
          canEdit={isWritable}
        />
        {/*<TableBlock title="Rappels" icon={{ name: 'bell' }} addButton>
          <NoContentMessage
            className="mb-5"
            icon={{ name: 'bolt' }}
            label="aucun rappel"
          />
        </TableBlock>*/}
      </div>
      <div className="flex flex-col-reverse md:flex-col gap-2 mt-2">
        <ShowIfAllowed>
          <Comments task={task} />
          <TaskRulesBlock task={task} />
          <TableBlock
            title={t('tasks.taskOptions')}
            icon={{ name: 'cogs' }}
            contentClassName="px-3 pb-2 flex flex-wrap gap-2"
          >
            <ChangeStatus task={task} reloadProject={reloadProject}>
              {(isChangingStatus): React.ReactNode => (
                <IconButton
                  icon={{ name: 'down' }}
                  disabled={isChangingStatus}
                  isLoading={isChangingStatus}
                  className="uppercase"
                >
                  {t('tasks.change_status')}
                </IconButton>
              )}
            </ChangeStatus>
            {isMediaTreeEmpty && hideMediaTreeIfEmpty ? (
              <AddButton onClick={revealMediaTree}>
                {t('medias.add_attachment', {
                  count: Number.POSITIVE_INFINITY,
                })}
              </AddButton>
            ) : null}
            <IconButton
              icon={{ name: isTaskConflictUpdating ? 'loader' : 'warning' }}
              disabled={isTaskConflictUpdating}
              onClick={updateTaskConflict}
            >
              {t(
                task.isConflictCheck
                  ? 'tasks.hide_conflicts'
                  : 'tasks.show_conflicts',
              )}
            </IconButton>
            <ChecklistCreateEditModal
              isOpen={isCreatingChecklist}
              setIsOpen={setIsCreatingChecklist}
              reloadProject={reloadProject}
              task={task}
            />
            <IconButton
              icon={{ name: 'list-check' }}
              onClick={switchIsCreatingChecklist}
            >
              {t('create.checklist')}
            </IconButton>
          </TableBlock>
        </ShowIfAllowed>
      </div>
    </div>
  );
};
