import {
  Group,
  type IGroupPatchData,
} from '@smack/core/api/models/tasks/Group';
import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import { SaveButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { useNonInitialEffect } from '@smack/core/utils/NonInitialEffect';
import React from 'react';
import { type SubmitHandler, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>;
  project: Project;
  group?: Group;
  reloadProject: () => void;
}

interface IFormValues {
  label: string;
  position?: Option;
}

export const GroupCreateEditModal: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  group,
  project,
  reloadProject,
}) => {
  const isEdit = !!group;

  const { register, handleSubmit, formState, reset, control } =
    useForm<IFormValues>();

  const resetForm = (): void => {
    reset({
      label: group?.label || '',
      position: undefined,
    });
  };

  const { field } = useController({
    name: 'position',
    control,
  });

  const { t } = useTranslation();

  const positionOptions = React.useMemo(
    () => [
      {
        value: -1,
        label: t('formInputLabels.firstPlace'),
      },
      ...(project.groups
        ?.filter((filteredGroup) => filteredGroup.id !== group?.id)
        .map<Option>((mappedGroup) => ({
          value: mappedGroup.position ?? 0,
          label: mappedGroup.label || mappedGroup.id,
        })) || []),
    ],
    [project],
  );

  const onSubmit = React.useCallback<SubmitHandler<IFormValues>>(
    async (values) => {
      const position = values.position
        ? (values.position.value as number) + 1
        : undefined;
      const data: IGroupPatchData = {
        label: values.label,
        position,
      };
      if (isEdit) {
        await group?.patch(data);
      } else {
        await Group.createGroup(project.id, values.label, position);
        resetForm();
      }
      setIsOpen(false);
      reloadProject();
    },
    [],
  );

  React.useEffect(() => {
    resetForm();
  }, [group]);

  useNonInitialEffect(() => {
    if (isOpen) reset();
  }, [isOpen]);

  const id = group?.id || 'create';

  return (
    <Modal
      open={isOpen}
      onClose={setIsOpen}
      icon={{ name: isEdit ? 'pen' : 'plus' }}
      title={t(isEdit ? 'edit.group' : 'create.group')}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3.5">
        <Input
          native
          id={`group-${id}`}
          label={t('formInputLabels.name')}
          error={formState.errors.label?.message}
          {...register('label', {
            required: {
              value: true,
              message: t('formValidation.required'),
            },
          })}
        />
        {positionOptions.length ? (
          <SelectInput
            id={`group-position-${id}`}
            label={t('formInputLabels.placeAfter')}
            options={positionOptions}
            onChange={field.onChange}
            value={field.value}
          />
        ) : null}
        <div className="mt-2 flex items-center justify-end">
          <SaveButton type="submit" data-testid="GroupEditModalSubmitButton" />
        </div>
      </form>
    </Modal>
  );
};
