import type { IIconProps } from '@smack/core/components/DataDisplay/Icon/Icon';
import { InputIcon } from '@smack/core/components/DataDisplay/InputIcon';
import {
  AffixChip,
  type affixChipVariants,
} from '@smack/core/components/DataInput/AffixChip/AffixChip';
import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

export interface IInputProps
  extends Omit<
    React.HTMLProps<HTMLInputElement>,
    'onChange' | 'ref' | 'prefix' | 'className' | 'size'
  > {
  native?: never;
  label?: string; // label of the input
  onChange?: (data: string) => void; // onChange methods
  placeholder?: string; // placeholder of the input
  error?: string; // if error in red
  helpText?: string; // gray test on the bottom of the input
  icon?: IIconProps; // to add an icon on the side
  iconSide?: 'left' | 'right';
  prefix?: React.ReactNode; // to add a prefix on the left side
  suffix?: React.ReactNode; // to add a suffix on the right side
  size?: VariantProps<typeof innerInputVariants>['size'];
  padding?: VariantProps<typeof innerInputVariants>['padding'];
  contentTextSize?: VariantProps<typeof innerInputVariants>['contentTextSize'];
  affixPadding?: VariantProps<typeof affixChipVariants>['padding'];
}

export interface INativeInputProps
  extends Omit<IInputProps, 'onChange' | 'native'> {
  native: true;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const innerInputVariants = cva(
  [
    'block',
    'max-w-lg',
    'bg-primary',
    'dark:bg-view',
    'focus:ring-blue-400',
    'focus:border-blue-400',
    'text-text',
    'shadow-sm',
    'flex-1',
  ],
  {
    variants: {
      status: {
        error: ['border-red-600'],
        neutral: ['border-gray-300', 'dark:border-neutral-500'],
      },
      leftBorder: {
        rounded: ['rounded-l-md'],
        sharp: [],
      },
      rightBorder: {
        rounded: ['rounded-r-md'],
        sharp: [],
      },
      size: {
        fluid: ['w-full'],
        small: ['w-32', 'sm:max-w-xs'],
        medium: ['w-48'],
        xSmall: ['w-24'],
      },
      padding: {
        leftSide: ['pl-8'],
        rightSide: ['pr-8'],
        both: ['pl-8', 'pr-8'],
        none: [],
      },
      contentTextSize: {
        sm: 'sm:text-sm',
        xs: 'sm:text-xs',
      },
    },
    defaultVariants: {
      status: 'neutral',
      leftBorder: 'rounded',
      rightBorder: 'rounded',
      size: 'fluid',
      contentTextSize: 'sm',
      padding: 'none',
    },
  },
);

const InputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IInputProps | INativeInputProps
> = (
  {
    label,
    native,
    onChange,
    helpText,
    iconSide,
    error,
    icon,
    prefix,
    suffix,
    children,
    size = 'fluid',
    padding,
    contentTextSize,
    affixPadding,
    ...htmlProps
  },
  ref,
): JSX.Element => {
  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      if (onChange) {
        if (native) onChange(e);
        else onChange(e.target.value);
      }
    },
    [onChange, native],
  );

  return (
    <div className={size === 'fluid' ? 'w-full' : ''}>
      {label && (
        <label
          htmlFor={htmlProps.id}
          className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1"
        >
          {label}
        </label>
      )}
      <div
        className={`flex max-w-lg w-full rounded-md ${
          htmlProps.disabled ? 'opacity-50' : ''
        }`}
      >
        {prefix && (
          <AffixChip type="prefix" padding={affixPadding}>
            {prefix}
          </AffixChip>
        )}
        <InputIcon
          iconProps={{
            ...icon,
          }}
          side={iconSide}
          className=""
          error={!!error}
        >
          {(providedClassName): JSX.Element => (
            <>
              <input
                data-testid="text-input"
                onChange={handleChange}
                className={innerInputVariants({
                  status: error ? 'error' : 'neutral',
                  leftBorder: prefix ? 'sharp' : 'rounded',
                  rightBorder: suffix ? 'sharp' : 'rounded',
                  size,
                  padding,
                  contentTextSize,
                  className: providedClassName,
                })}
                ref={ref}
                {...htmlProps}
              />
              {children}
            </>
          )}
        </InputIcon>
        {suffix && (
          <AffixChip type="suffix" padding={affixPadding}>
            {suffix}
          </AffixChip>
        )}
      </div>
      {helpText && (
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
          {helpText}
        </p>
      )}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export const Input = React.forwardRef(InputRender);
