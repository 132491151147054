import { ChecklistItemLog } from '@smack/core/api/models/tasks/ChecklistItemLog';
import { ChecklistLog } from '@smack/core/api/models/tasks/ChecklistLog';
import type { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import { ChecklistItemLogFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistItemLogFeed';
import { ChecklistLogFeed } from '@smack/core/views/Feeds/LogsFeed/ChecklistLogFeed';
import { TaskLogFeed } from '@smack/core/views/Feeds/LogsFeed/TaskLogFeed';
import type React from 'react';

interface IProps {
  log: TaskLog | ChecklistLog | ChecklistItemLog;
  first: boolean;
  last: boolean;
}

export const LogsDispatcher: React.FC<IProps> = (props) => {
  if (props.log instanceof ChecklistLog) {
    return <ChecklistLogFeed {...props} />;
  }
  if (props.log instanceof ChecklistItemLog) {
    return <ChecklistItemLogFeed {...props} />;
  }
  return <TaskLogFeed {...props} />;
};
