import type { File, FitMode } from '@smack/core/api/models/medias/File';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';

interface IProps {
  image: File;
  fit?: FitMode;
  width?: number;
  height?: number;
  isClickable?: boolean;
  alt?: string;
  className?: string;
}

export const ImageThumbnail = (props: IProps): JSX.Element => {
  const { image, fit, width, height, isClickable, alt, className } = props;

  return (
    <>
      {isClickable ? (
        <div
          onClick={image.open.bind(image)}
          className={
            'transition-opacity ease-linear opacity-0 hover:opacity-100 absolute bg-black bg-opacity-30 top-0 bottom-0 left-0 right-0 flex items-center justify-center cursor-pointer'
          }
        >
          <Icon
            icon={{ name: 'magnifying-glass-plus' }}
            className="text-white text-3xl"
          />
        </div>
      ) : null}

      <img
        src={image.getThumbnailSrc(fit, width, height)}
        className={className}
        alt={alt}
      />
    </>
  );
};
