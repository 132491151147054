import { StringUtils } from '@smack/core/utils/String';
import type React from 'react';
import { useCallback } from 'react';

export interface UrlProps {
  src?: string;
  isDeferred?: boolean;
  getDeferredValue?: () => Promise<string>;
}

export const Url: React.FC<UrlProps> = ({
  src,
  getDeferredValue,
  isDeferred,
}) => {
  const onClick = useCallback(() => {
    if (isDeferred && getDeferredValue) {
      getDeferredValue().then((res) => {
        const url = StringUtils.sanitizeHRefLink(res);
        window.open(url, '_blank');
      });
    } else {
      const url = StringUtils.sanitizeHRefLink(src);
      window.open(url, '_blank');
    }
  }, [src, getDeferredValue]);

  return (
    <p
      onClick={onClick}
      className="text-sm  truncate max-w-[36ch] text-blue-500 underline hover:text-blue-600 cursor-pointer "
    >
      {src}
    </p>
  );
};
