import type { IAttributeValue } from '@smack/core/api/models/categories/Attribute/Attribute';
import type { IRevisionChangesDetails } from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  type ICellType,
  Table,
} from '@smack/core/components/DataDisplay/Table/Table';
import type { IAttributeValueFromType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import type { CellContext } from '@tanstack/react-table';
import { t } from 'i18next';
import React from 'react';

interface IProps {
  loading: boolean;
  revisionDetails?: IRevisionChangesDetails[];
  baseObject: BaseObject;
}

export const RevisionDetails = (props: IProps): JSX.Element => {
  const { revisionDetails, loading, baseObject } = props;

  const renderCell = ({
    getValue,
    row,
  }: CellContext<ICellType, unknown>): React.ReactNode => {
    const value = getValue() as IAttributeValue<string>[];
    const { isMultiple, element } = row.original as {
      isMultiple: boolean;
      element: IAttributeValueFromType;
    };

    return isMultiple
      ? element.renderMultipleValues(value, ViewUsage.SHEET)
      : element.renderValue(value[0], ViewUsage.SHEET);
  };

  const columns = [
    {
      header: t('revisions.attribute'),
      accessorKey: 'label',
      size: 274,
      enableResizing: true,
    },
    {
      header: t('revisions.previousValue'),
      accessorKey: 'previousValue',
      cell: renderCell,
      size: 274,
      enableResizing: true,
    },
    {
      header: t('revisions.newValue'),
      accessorKey: 'newValue',
      cell: renderCell,
      size: 274,
      enableResizing: true,
    },
  ];

  return (
    <div data-testid="revision-history-content" className={'w-full'}>
      {loading ? (
        <div className="w-full flex-grow align-middle">
          <LoaderSkeleton height={350} width="100%" className="">
            <rect x="0" y="0" width="100%" height="45" rx="8" />
            {Array.from({ length: 8 }, (_, i) => i).map((rowIndex) => (
              <React.Fragment key={rowIndex}>
                {Array.from({ length: 3 }, (_, i) => i).map((columnIndex) => (
                  <rect
                    key={columnIndex}
                    x={0 + columnIndex * 283}
                    y={50 + rowIndex * 40}
                    height="35"
                    width="268"
                    rx="8"
                  />
                ))}
              </React.Fragment>
            ))}
          </LoaderSkeleton>
        </div>
      ) : (
        <div
          data-testid="data-table- revisions"
          className="w-full my-2 h-[350px] overflow-y-none"
        >
          <Table
            columns={columns ?? []}
            data={revisionDetails as unknown as ICellType[]}
          />
        </div>
      )}
    </div>
  );
};
