import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import { Bookmark } from '@smack/core/api/models/bookmarks/Bookmark';
import { Attribute } from '@smack/core/api/models/categories/Attribute/Attribute';
import {
  Category,
  type ICategory,
} from '@smack/core/api/models/categories/Category';
import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import { File, type FileApiOutput } from '@smack/core/api/models/medias/File';
import type { ILink } from '@smack/core/api/models/objects/Link/Link';
import type { ListBlockDisplayApiOutput } from '@smack/core/api/models/views/ListBlockDisplay/ListBlockDisplay';
import type { BaseObjectSubmitAttribute } from '@smack/core/components/ViewRenderer/interfaces';
import { AttributeType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import {
  type IFilters,
  getParamsFromFilters,
  queryParamsByMode,
} from '@smack/core/utils/Filters';
import type { AxiosResponse } from 'axios';
import type { Point } from 'geojson';
import type { LngLatLike } from 'maplibre-gl';
import type { RRule } from 'rrule';
import { CommonBaseObject, type ICommonBaseObject } from '..';
import {
  type INotificationState,
  NotificationState,
} from '../../NotificationState';
import { type IMapBaseObject, MapBaseObject } from '../MapBaseObject';

export interface IBaseObject extends ICommonBaseObject {
  category?: ICategory;
  thumbnail?: FileApiOutput;
  geometry?: GeoJSON.FeatureCollection;
  location?: GeoJSON.Feature<Point>;
  notificationState?: INotificationState;
  rrule?: string;
  baseobjectGroupId?: number;
}

/**
 * BaseObject class for BaseObject Model.
 * Used for BaseObject list and retrieve.
 *
 * @export
 * @class BaseObject
 */
export class BaseObject
  extends CommonBaseObject<ICommonBaseObject>
  implements ICommonBaseObject
{
  category?: Category;

  geometry?: GeoJSON.FeatureCollection;

  thumbnail?: File;

  location?: GeoJSON.Feature<Point>;

  notificationState?: NotificationState;

  rrule?: string;

  bookmark?: Bookmark;

  baseobjectGroupId?: number;

  constructor(data: IBaseObject) {
    super(data);

    if (data.category) {
      this.category = new Category(data.category);
    }
    if (data.notificationState) {
      this.notificationState = new NotificationState(data.notificationState);
    }
    this.geometry = data.geometry;
    if (data.thumbnail) {
      this.thumbnail = new File(data.thumbnail);
    }
    this.location = data.location;
    this.scheduleId = data.scheduleId;
    this.rrule = data.rrule;
    this.baseobjectGroupId = data.baseobjectGroupId;
    if (data.bookmarkId) {
      this.bookmark = new Bookmark({ id: data.bookmarkId });
    }
  }

  /**
   * Get the base object for the panel header
   *
   * @static
   * @param {(number | string)} id
   * @return {*}  {Promise<BaseObject>}
   * @memberof BaseObject
   */
  static getBaseObject(
    id: number | string,
    scheduleId?: number | string,
  ): Promise<BaseObject> {
    return RESTClient.get<{ data: { results: IBaseObject } }>(
      `/objects/baseobjects/${id}?with-badge=1&with-schedule=1`,
      { schedule: scheduleId || null },
    ).then((res) => new BaseObject(res.data?.results));
  }

  /**
   * Get baseObject From LinksGroup for listElement representation
   *
   * @param {number} linkGroupId
   * @param {number} limit
   * @param {number} offset
   * @return {*}  {Promise<{
   *     data: {
   *       count: number;
   *       next: string;
   *       previous: string;
   *       results: IBaseObject[];
   *     };
   *   }>}
   * @memberof CommonBaseObject
   */
  getLinksFromLinkGroup(
    linkGroupId: number,
    limit: number,
    offset: number,
  ): Promise<{
    data: IPagination<ILink<ListBlockDisplayApiOutput>>;
  }> {
    return RESTClient.get<{
      data: IPagination<ILink<ListBlockDisplayApiOutput>>;
    }>(`/objects/baseobjects/${this.id}/link-groups/${linkGroupId}/links`, {
      limit,
      offset,
      'with-badge': true,
    });
  }

  /**
   * Get baseObject From LinksGroup from room in calendar representation
   * @param linkGroup
   * @param sourceBaseObjectId
   */
  static getBaseObjectRoomFormCalendarRepresentation(
    linkGroup: LinkGroup,
    sourceBaseObjectId: number,
  ): Promise<{ baseobject: BaseObject; group: string }[]> {
    return RESTClient.get<{
      data: {
        results: IBaseObject[];
      };
    }>(
      `/objects/baseobjects?link-group=${linkGroup.id}&source-baseobject=${sourceBaseObjectId}&with-schedule=1`,
    ).then((res) =>
      res.data.results.map((baseObject) => ({
        baseobject: new BaseObject(baseObject),
        group: linkGroup.label || '',
      })),
    );
  }

  static getBaseObjectsForTimelineRepresentation(
    sourceBaseObjectId: number,
    dateStart: string,
    dateEnd: string,
  ): Promise<{ baseobject: BaseObject; sourceId: number }[]> {
    return RESTClient.getIteratePages<{
      data: {
        results: IBaseObject[];
      };
    }>(
      `/objects/baseobjects?&room-calendar-baseobject=${sourceBaseObjectId}&date-start=${dateStart}&date-end=${dateEnd}&with-subtitle=1&with-schedule=1`,
    ).then((res) =>
      res.data.results.map((baseObject) => ({
        baseobject: new BaseObject(baseObject),
        sourceId: sourceBaseObjectId,
      })),
    );
  }

  static getLinkedBaseObjectsForCalendarRepresentation(
    sourceBaseObjectId: number,
    dateStart: string,
    dateEnd: string,
  ): Promise<{ baseobject: BaseObject; sourceId: number }[]> {
    return RESTClient.getIteratePages<{
      data: {
        results: IBaseObject[];
      };
    }>(
      `/objects/baseobjects?&rooms-calendar-baseobject=${sourceBaseObjectId}&date-start=${dateStart}&date-end=${dateEnd}&with-subtitle=1&with-schedule=1`,
    ).then((res) =>
      res.data.results.map((baseObject) => ({
        baseobject: new BaseObject(baseObject),
        sourceId: sourceBaseObjectId,
      })),
    );
  }

  /**
   * Get baseObject From LinksGroup for map representation
   *
   * @param {number} linkGroupId
   * @param {number} limit
   * @param {number} offset
   * @return {*}  {Promise<{
   *     data: {
   *       count: number;
   *       next: string;
   *       previous: string;
   *       results: IMapBaseObject[];
   *     };
   *   }>}
   * @memberof CommonBaseObject
   */
  async getBaseObjectForMapRepresentationByLinksGroup(
    linkGroupId: number,
  ): Promise<MapBaseObject[]> {
    let response = await RESTClient.get<{
      data: {
        count: number;
        next: string;
        previous: string;
        results: IMapBaseObject[];
      };
    }>(
      '/objects/baseobjects?with-location=1',
      getParamsFromFilters({}, queryParamsByMode.map, {
        links: { linkGroupId: linkGroupId, sourceBaseObjectId: this.id },
        limit: 1000,
      }),
    );
    const results: MapBaseObject[] = response.data.results.map(
      (o) => new MapBaseObject(o),
    );
    let nextPage = response.data.next;
    while (nextPage) {
      response = await RESTClient.get<{
        data: {
          count: number;
          next: string;
          previous: string;
          results: IMapBaseObject[];
        };
      }>(nextPage, undefined, true);
      results.push(...response.data.results.map((o) => new MapBaseObject(o)));
      nextPage = response.data.next;
    }
    return results;
  }

  /**
   * fly to the base object on the map
   *
   * @param {(maplibregl.Map | null)} [map]
   * @return {*}  {void}
   * @memberof BaseObject
   */
  flyTo(map?: maplibregl.Map | null): void {
    if (!this.location && map) return;
    map?.flyTo({
      center: this.location?.geometry.coordinates as LngLatLike,
      zoom: 17,
    });
  }

  accept(): Promise<{ frontEndpoint: string }> {
    return RESTClient.post<{ results: { frontEndpoint: string } }>(
      {},
      `/objects/notifications/${this.id}/accept`,
    ).then((res) => res.data?.results);
  }

  reject(description?: string): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      { description: description ? description : null },
      `/objects/notifications/${this.id}/reject`,
    );
  }

  delete(): Promise<void> {
    return RESTClient.delete(`/objects/baseobjects/${this.id}`);
  }

  async createBookmark(): Promise<void> {
    const response = await RESTClient.post<{ id: number }>(
      { baseobject: this.id },
      '/bookmarks/bookmarks',
    );
    this.bookmark = new Bookmark({ id: response.data.id });
  }

  async deleteBookmark(): Promise<void> {
    if (!this.bookmark) return Promise.reject('This object is not bookmarked');
    await this.bookmark.delete();
    this.bookmark = undefined;
  }

  touchBookmark(): Promise<void> {
    if (this.bookmark) return this.bookmark.touch();
    return Promise.reject('This object is not bookmarked');
  }

  createTimeSeries(
    rrule: RRule | undefined,
    startAt: Date | undefined,
    endAt: Date | undefined,
    excludeCategoryId: number | undefined,
  ): Promise<{ id: number }> {
    return RESTClient.post<{ id: number }>(
      {
        groupType: 'TIME_SERIES',
        recurrence: rrule?.toString(),
        baseOccurrenceDatetimeRange: {
          dtstart: startAt?.toISOString(),
          dtend: endAt?.toISOString(),
        },
        exCategoryId: excludeCategoryId,
      },
      `/objects/baseobjects/${this.id}/baseobject-group`,
    ).then((res) => res.data);
  }

  duplicate(
    copyAttributes: boolean,
    copyMedias: boolean,
  ): Promise<{ id: number }> {
    return RESTClient.post<{ results: { id: number } }>(
      {
        copyAttributes: copyAttributes,
        copyMedias: copyMedias,
      },
      `/objects/baseobjects/${this.id}/copies`,
    ).then((res) => res.data.results);
  }

  static getLinkableBaseObjects(
    linkGroupId: number,
    filters: IFilters = {},
    parameters?: Record<string, unknown>,
  ): Promise<IPagination<IBaseObject>> {
    return RESTClient.get<AxiosResponse<IPagination<IBaseObject>>>(
      '/objects/baseobjects',
      getParamsFromFilters(filters, queryParamsByMode.standard, {
        links: {
          linkGroupId,
          includeLinkables: true,
        },
      }),
    ).then((res) => res.data);
  }

  static async patchBaseObjectRecurrences(
    baseObject: BaseObject,
    recurrenceData: {
      dtstart?: string;
      dtend?: string;
    },
  ): Promise<{ id: number }> {
    const attributes: Attribute[] = await Attribute.getByTypeAndCategoryId(
      baseObject.category?.id ?? 0,
      AttributeType.RECURRENCE,
    );
    const newAttributes: BaseObjectSubmitAttribute[] = [];
    for (const att of attributes) {
      newAttributes.push({
        id: att.id,
        values: [recurrenceData],
      });
    }
    return BaseObject.patchBaseObject(baseObject.id, {
      attributes: newAttributes,
    });
  }

  static getBaseObjectsCountByCategoryId(categoryId: number): Promise<number> {
    return RESTClient.get<AxiosResponse<IPagination<IBaseObject>>>(
      `/objects/baseobjects?category-in[]=${categoryId}&limit=0`,
    ).then((res) => res.data.count ?? 0);
  }
}
