import { Comment } from '@smack/core/api/models/comments/Comment';
import { BaseObjectRevision } from '@smack/core/api/models/objects/BaseObjectRevision/BaseObjectRevision';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { IconFeed } from '@smack/core/components/DataDisplay/Feeds/IconFeed';
import type { AppState } from '@smack/core/store';
import {
  ListSkeleton,
  LoaderSkeleton,
  LogSkeleton,
} from '@smack/core/utils/Loader';
import { CommentFeed } from '@smack/core/views/Feeds/CommentFeed';
import { isAfter, isBefore } from 'date-fns';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface IUseCommentsRevisionsProps<T extends BaseObjectRevision> {
  getComments(): Promise<Comment[]>;
  getRevisions(): Promise<T[]>;
  showComments: boolean;
  setShowComments?: (value: boolean) => void;
  showRevisions: boolean;
  setShowRevisions?: (value: boolean) => void;
  RevisionsComponent: React.FC<{
    revision: T;
    first: boolean;
    last: boolean;
    baseObject: BaseObject;
  }>;
  customEmptyNode?: React.ReactNode;
  baseObject: BaseObject;
}

type RefreshFunc = (options?: {
  revisions?: boolean;
  comments?: boolean;
}) => Promise<void>;

export const useCommentsRevisions = <T extends BaseObjectRevision>({
  getComments,
  getRevisions,
  showComments,
  setShowComments,
  showRevisions,
  RevisionsComponent,
  customEmptyNode,
  baseObject,
}: IUseCommentsRevisionsProps<T>): [React.ReactNode, boolean, RefreshFunc] => {
  const [t] = useTranslation();
  const [comments, setComments] = React.useState<Comment[]>([]);
  const [revisions, setRevisions] = React.useState<T[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { current } = useSelector((app: AppState) => app.User);
  const feed = React.useMemo<React.ReactNode | React.ReactNode[]>(() => {
    const elements: (Comment | T)[] = [];
    if (showComments) elements.push(...comments);
    if (showRevisions) elements.push(...revisions);
    elements.sort((a, b): number => {
      let aDate = new Date();
      let bDate = new Date();
      if (a instanceof Comment) aDate = a.createdAt;
      if (a instanceof BaseObjectRevision) aDate = a.appliedAt;
      if (b instanceof Comment) bDate = b.createdAt;
      if (b instanceof BaseObjectRevision) bDate = b.appliedAt;
      if (isBefore(aDate, bDate)) return 1;
      if (isAfter(aDate, bDate)) return -1;
      return 0;
    });
    if (elements.length) {
      return elements.map((element, i) => {
        if (element instanceof Comment) {
          return (
            <CommentFeed
              key={`comment-${element.id}`}
              comment={element}
              last={i === 0}
              first={i === elements.length - 1}
              editButton={
                current?.isAdmin || current?.id === element.getUser()?.id
              }
              isModified={
                element.updateCount !== null && element.updateCount !== 0
              }
            />
          );
        }
        return (
          <RevisionsComponent
            key={`revisions-${element.id}`}
            revision={element}
            baseObject={baseObject}
            last={i === 0}
            first={i === elements.length - 1}
          />
        );
      });
    }
    if (isLoading) {
      return (
        <LoaderSkeleton height={350} width="100%">
          <ListSkeleton iterations={5} component={LogSkeleton} />
        </LoaderSkeleton>
      );
    }
    if (customEmptyNode) {
      return customEmptyNode;
    }
    if (comments.length && !showComments) {
      return (
        <IconFeed
          icon={{ name: 'filter-list' }}
          roundColor="#F3F4F6"
          iconColor="gray"
          hideLine
        >
          <div className="py-5 px-2 h-full">
            <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
              <Trans
                t={t}
                i18nKey="comments.noCommentsWithAction"
                components={{
                  comments: (
                    <button
                      type="button"
                      className="inline text-blue-400 hover:text-blue-600"
                      onClick={(): void => setShowComments?.(true)}
                    />
                  ),
                }}
                count={comments.length}
              />
            </p>
          </div>
        </IconFeed>
      );
    }
    const isContentHiddenByFilters =
      (!showComments && comments.length) ||
      (!showRevisions && revisions.length);
    return (
      <IconFeed
        icon={{ name: 'empty-set' }}
        roundColor="#F3F4F6"
        iconColor="gray"
        hideLine
      >
        <div className="py-5 px-2 h-full">
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
            {t(
              isContentHiddenByFilters
                ? 'comments.noCommentsFiltered'
                : 'comments.noComments',
            )}
          </p>
        </div>
      </IconFeed>
    );
  }, [isLoading, comments, revisions, showComments, showRevisions]);

  const reload = React.useCallback<RefreshFunc>(
    ({
      comments: refreshComments = true,
      revisions: refreshRevisions = true,
    } = {}) => {
      setIsLoading(true);
      const promiseArr: Promise<void>[] = [];
      if (refreshComments) promiseArr.push(getComments().then(setComments));
      if (refreshRevisions) promiseArr.push(getRevisions().then(setRevisions));
      return Promise.all(promiseArr)
        .then(() => {})
        .finally(() => setIsLoading(false));
    },
    [getRevisions, getComments],
  );

  React.useEffect(() => {
    reload();
  }, []);

  return [feed, isLoading, reload];
};
