import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import React, { useContext } from 'react';

import { File as FileModel, FitMode } from '@smack/core/api/models/medias/File';
import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { LinkCustom } from '@smack/core/components/Actions/Link';
import { ImageThumbnail } from '@smack/core/components/DataDisplay/ImageThumbnail/ImageThumbnail';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import type { AppState } from '@smack/core/store';
import { BaseObjectPanelContext } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/Context';
import { MiniMap } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/MiniMap';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export interface ICarouselProps {
  object?: BaseObject;
  mobile?: boolean;
  onClose: () => void;
}

export const HeaderCarrousel = (props: ICarouselProps): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigation();
  const { object, onClose, mobile } = props;
  const [active, setActive] = React.useState<number>(0);
  const [data, setData] = React.useState<FileModel[]>([]);
  const { carouselReloadIncrement } = useContext(BaseObjectPanelContext);
  const pointMode = useSelector((state: AppState) => state.Maps.pointMode);
  const onStreetMap = (): void => {
    const point = object?.location;
    if (!point) return;
    window.open(
      `https://www.google.com/maps?layer=c&cbll=${point.geometry.coordinates[1]},${point.geometry.coordinates[0]}`,
      '_blank',
      'toolbar=0,location=0,menubar=0,width=500,height=500',
    );
  };

  const favoriteFileToImage = (file: FileModel): JSX.Element => {
    return (
      <div
        key={file.id}
        className={`w-[400px] h-44 relative bg-repeat bg-[length:50%] ${
          mobile ? 'w-screen' : ''
        }`}
      >
        <div className="backdrop-blur-md h-full w-full flex items-center justify-center">
          <ImageThumbnail
            image={file}
            fit={FitMode.CROP}
            width={400}
            height={200}
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    );
  };

  const getBaseContent = (favoriteImages?: FileModel[]): JSX.Element[] => {
    const output: JSX.Element[] = [];
    if (object?.thumbnail) {
      output.push(
        <div
          key={object?.thumbnail.id}
          className={`w-[400px] h-44 relative bg-repeat bg-[length:50%] ${
            mobile ? 'w-screen' : ''
          }`}
        >
          <div className="img-blur">
            <ImageThumbnail
              image={object.thumbnail}
              fit={FitMode.CROP}
              width={400}
              height={200}
              className="object-cover w-full h-full"
            />
          </div>
        </div>,
      );
    }
    // add favorites, avoid duplicate if thumbnail is favorite too
    favoriteImages
      ?.filter((image) => image.id !== object?.thumbnail?.id)
      ?.forEach((image) => {
        output.push(favoriteFileToImage(image));
      });
    output.push(
      <MiniMap
        mobile={mobile}
        key="minimap"
        location={object?.location}
        geometry={object?.geometry}
      />,
    );
    return output;
  };

  const [content, setContent] = React.useState<JSX.Element[]>(getBaseContent());

  React.useEffect(() => {
    object?.mediasManager
      .listFiles(undefined, true, 'IMAGE')
      .then((res) =>
        setData(res.data.results?.map((file) => new FileModel(file))),
      )
      .catch(() => toast.error('Impossible de récupérer les images favories'));
  }, [object, mobile, carouselReloadIncrement]);

  const flyTo = React.useCallback(() => {
    const { MainMap: HyviloMap } = window.Hyvilo.Utils;
    if (!object && !HyviloMap) return;
    if (object?.location) {
      HyviloMap?.flyTo(
        {
          center: [
            object.location.geometry?.coordinates[0],
            object.location.geometry?.coordinates[1],
          ],
          zoom: HyviloMap?.getMaxZoom() || 19,
        },
        { openClusterAfterFlying: true },
      );
      if (pointMode === 'legacyCluster') {
        HyviloMap?.once('moveend', () => {
          // Click the now-centered cluster
          const mapCanvas = HyviloMap.getCanvas();
          const mapRect = mapCanvas.getBoundingClientRect();
          const centerCoordinates = {
            x: mapRect.left + mapRect.width / 2,
            y: mapRect.top + mapRect.height / 2,
          };
          if (mapCanvas) {
            const elements = document.elementsFromPoint(
              centerCoordinates.x,
              centerCoordinates.y,
            );
            const element = elements.find((el) =>
              el.classList.contains('maplibregl-marker'),
            );
            if (!element) return;
            const initDict: MouseEventInit = {
              clientX: centerCoordinates.x,
              clientY: centerCoordinates.y,
              // screenX: centerCoordinates.x,
              // screenY: centerCoordinates.y,
              movementX: centerCoordinates.x,
              movementY: centerCoordinates.y,
              bubbles: true,
            };
            element.dispatchEvent(new MouseEvent('mousedown', initDict));
            element.dispatchEvent(new MouseEvent('mouseup', initDict));
            element.dispatchEvent(new MouseEvent('click', initDict));
          }
        });
      }
    }
  }, [object]);

  const copyObjectId: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (!object) return;
    window.getSelection()?.selectAllChildren(e.currentTarget);
    navigator.clipboard.writeText(
      `${object.id}${
        object.scheduleId ? `/${object.scheduleId.toString()}` : ''
      }`,
    );
  };

  React.useEffect(() => {
    setContent(getBaseContent(data));
  }, [data]);

  const getWidth = (): number => {
    if (mobile) return window.innerWidth;
    return 400;
  };

  const [isShowingIds, setIsShowingIds] = React.useState(false);

  const showIds = React.useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (!Object.is(e.currentTarget, e.target)) return;
      setIsShowingIds(true);
    },
    [],
  );

  const isObjectLocated = !!object?.location;

  return (
    <div className="w-full h-44 flex relative">
      <div className="w-full h-full overflow-hidden" data-content="container">
        <div
          style={{
            transform: `translatex(calc(-${getWidth()}px * ${active}))`,
          }}
          className="transition-all ease-linear w-fit flex"
        >
          {content}
        </div>
      </div>
      {isShowingIds ? (
        <div
          className="absolute text-xs z-30 bottom-2 left-3 h-4 w-fit flex items-center opacity-40 hover:opacity-95 bg-white dark:bg-gray-200 shadow rounded px-3"
          onClick={copyObjectId}
        >
          {object?.id}
          {object?.scheduleId ? `/${object.scheduleId.toString()}` : ''}
        </div>
      ) : null}
      <Tooltip title={t('baseObjectPanel.backButton')} position="top">
        <div
          onClick={(): void => navigate(-1)}
          className="absolute z-30 flex items-center justify-center top-2 right-12 h-7 w-7 bg-white dark:bg-gray-200 hover:bg-gray-100 shadow rounded cursor-pointer"
        >
          <Icon icon={{ name: 'arrow-left' }} />
        </div>
      </Tooltip>
      <Tooltip title={t('baseObjectPanel.closeButton')} position="top">
        <LinkCustom active={!mobile} to="../">
          <div
            onClick={onClose}
            className="absolute z-30 flex items-center justify-center top-2 right-3 h-7 w-7 bg-white dark:bg-gray-200 hover:bg-gray-100 shadow rounded"
          >
            <Icon icon={{ name: 'times' }} />
          </div>
        </LinkCustom>
      </Tooltip>
      <IconRoundedButton
        onClick={flyTo}
        className="absolute z-30 right-12 bottom-2"
        disabled={!isObjectLocated}
        icon={{ name: 'map-marker-alt' }}
        title={t(
          isObjectLocated
            ? 'baseObjectPanel.centerOnMap'
            : 'baseObjectPanel.objectIsNotLocated',
        )}
        position="bottom-end"
      />
      <IconRoundedButton
        onClick={onStreetMap}
        className="absolute z-30 right-3 bottom-2"
        disabled={!isObjectLocated}
        icon={{ name: 'street-view' }}
        title={t(
          isObjectLocated
            ? 'baseObjectPanel.openStreetView'
            : 'baseObjectPanel.objectIsNotLocated',
        )}
        position="bottom-end"
      />
      <div
        className="absolute bg-black bg-opacity-10 inset-0 flex items-center justify-between p-5"
        onClick={showIds}
      >
        <div
          onClick={(): void => {
            if (active !== 0) setActive(active - 1);
          }}
          className={`text-3xl text-white cursor-pointer opacity-70 hover:opacity-100 ${
            active === 0 ? '!opacity-0 cursor-default' : ''
          }`}
        >
          <Icon className="icon" icon={{ name: 'chevron-left' }} />
        </div>
        <div
          onClick={(): void => {
            if (active !== content.length - 1) setActive(active + 1);
          }}
          className={`text-3xl text-white cursor-pointer opacity-70 hover:opacity-100 ${
            active === content.length - 1 ? '!opacity-0 cursor-default' : ''
          }`}
        >
          <Icon className="icon" icon={{ name: 'chevron-right' }} />
        </div>
      </div>
    </div>
  );
};

HeaderCarrousel.defaultProps = {
  mobile: false,
  ispublic: undefined,
};
